<template>
  <div class="message-functionalities">
    <b-dropdown class="dropdown-select px-2" variant="link" no-caret>
      <template #button-content>
        <i class="icon-Aula_elipses" />
      </template>
      <b-dropdown-item v-if="showDelete" @click="onDeleteMessageClicked">
        {{ 'DELETE' | fromTextKey }}
      </b-dropdown-item>
      <b-dropdown-item v-if="canEditMessage" @click="onEditMessageClicked">
        {{ 'EDIT' | fromTextKey }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageTypes } from '../../../shared/enums/messageTypes';

export default {
  inject: {
    onDeleteMessage: messageProviderKeyEnum.ON_DELETE_MESSAGE,
    onEditMessage: messageProviderKeyEnum.ON_EDIT_MESSAGE,
  },
  props: {
    message: { type: Object, default: () => {} },
    showDelete: { type: Boolean, default: true },
  },
  computed: {
    canEditMessage() {
      const allowedMessageTypes = [messageTypes.MESSAGE, messageTypes.MESSAGE_EDITED];
      return allowedMessageTypes.includes(this.message.messageType);
    },
  },
  methods: {
    onDeleteMessageClicked() {
      this.onDeleteMessage();
    },
    onEditMessageClicked() {
      this.onEditMessage(this.message);
    },
  },
};
</script>
