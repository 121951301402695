// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vuex from 'vuex';
import VueRouter from 'vue-router';

import Vue from 'vue';
import router from './router/index';

import { store } from './store/store';
import { types } from './store/types/types';
import { moduleTypes } from '../shared/enums/moduleTypes';

// Import vendors
import moment from 'moment-timezone';
moment.locale('da');
Vue.prototype.moment = moment;
import '../shared/vendors/elementUi';
import 'axios';
import 'intersection-observer'; // Polyfill for intersection observer.

// Bootstrap-vue
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

// Vue virtual scroller
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// Plugins
import StoragePlugin from '../shared/plugins/storage';

// Temporary fix for Vue and Bootstrap Vue
const originalVueComponent = Vue.component;
Vue.component = function (name, definition) {
  if (
    name === 'bFormCheckboxGroup' ||
    name === 'bCheckboxGroup' ||
    name === 'bCheckGroup' ||
    name === 'bFormRadioGroup'
  ) {
    definition.components = { bFormCheckbox: definition.components[0] };
  }
  originalVueComponent.apply(this, [name, definition]);
};
Vue.use(BootstrapVue);
Vue.component = originalVueComponent;

import PortalVue from 'portal-vue';

Vue.use(PortalVue);

import ClickOutside from 'vue-click-outside';
Vue.directive('click-outside', ClickOutside);

Vue.use(Vuex);
Vue.use(VueRouter);

// Import shared components
import App from './App';
import Messages from './components/messages/ShownSubscription.vue';
import Onboarding from './components/onboarding/Onboarding.vue';
import Posts from './components/posts/Posts.vue';
import Calendar from './components/calendar/Calendar.vue';
import Gallery from './components/gallery/Gallery.vue';
import AulaSearch from '../shared/components/AulaSearch.vue';
import UserIcon from './components/users/UserIcon.vue';
import PostTime from './components/shared/PostTime.vue';
import GroupPage from './components/groups/GroupPage.vue';
import AulaSpinner from '../shared/components/Spinner.vue';
import toastr from '../shared/components/Toastr.vue';
import Drawer from '../shared/components/Drawer';
import AulaModal from '../shared/components/AulaModal.vue';
import AulaFormFooter from '../shared/components/AulaFormFooter.vue';
import { datePickerModifier } from './components/shared/datePickerModifier';
import HelpBox from '../shared/components/HelpBox';
import Table from '../shared/components/Table.vue';
import WidgetHtml from '../shared/components/WidgetHtml';
import AulaSelect from '../shared/components/select/Select';
import AulaOption from '../shared/components/select/Option';

// Register global components
Vue.component('Messages', Messages);
Vue.component('Onboarding', Onboarding);
Vue.component('Posts', Posts);
Vue.component('Calendar', Calendar);
Vue.component('Gallery', Gallery);
Vue.component('AulaSearch', AulaSearch);
Vue.component('UserIcon', UserIcon);
Vue.component('PostTime', PostTime);
Vue.component('GroupPage', GroupPage);
Vue.component('AulaSpinner', AulaSpinner);
Vue.component('Drawer', Drawer);
Vue.component('Toastr', toastr);
Vue.component('AulaModal', AulaModal);
Vue.component('AulaFormFooter', AulaFormFooter);
Vue.component('HelpBox', HelpBox);
Vue.component('AulaTable', Table);
Vue.component('WidgetHtml', WidgetHtml);
Vue.component('AulaSelect', AulaSelect);
Vue.component('AulaOption', AulaOption);

// Import sass files
import '../shared/assets/scss/app.scss';
// Texts
import * as shared from '../shared/texts/shared.js';
import * as onboarding from './texts/onboarding.js';
import * as messages from './texts/messages.js';
import * as gallery from './texts/gallery.js';
import * as documents from './texts/documents.js';
import * as posts from './texts/posts.js';
import * as comeandgo from './texts/presence.js';
import * as calendar from './texts/calendar.js';
import * as calendarFeed from './texts/calendarFeed.js';
import * as groups from './texts/groups.js';
import * as global from '../shared/texts/global.js';
import * as notifications from './texts/notifications.js';
import * as consent from './texts/consent.js';
import * as editor from './texts/editor.js';
import * as mobile from './texts/mobile.js';
import * as contacts from './texts/contacts.js';
import * as comments from '../shared/texts/comments.js';
import * as personalReferenceData from './texts/personalReferenceData.js';
import * as overview from '../src_presence/texts/overview';

const texts = Object.assign(
  {},
  shared,
  onboarding,
  messages,
  gallery,
  contacts,
  comments,
  personalReferenceData,
  documents,
  posts,
  comeandgo,
  calendar,
  calendarFeed,
  groups,
  global,
  notifications,
  consent,
  editor,
  mobile,
  overview
);
import { fromTextKey } from '../shared/texts/fromTextKey';
Vue.filter('fromTextKey', function (textKey, properties) {
  return fromTextKey(textKey, properties, texts);
});

import '../shared/assets/plugins/filters';
import '../shared/assets/plugins/highlight';
import * as calendarFeedDeepLink from '../shared/assets/plugins/calendarFeedDeepLink';
import { moduleWidgetPlacements } from '../shared/enums/moduleWidgetPlacements';

const vueTouch = require('vue-touch');
Vue.use(vueTouch);

Vue.config.productionTip = false;

// includes for IE
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    enumerable: false,
    value: function (obj) {
      const newArr = this.filter(function (el) {
        return el == obj;
      });
      return newArr.length > 0;
    },
  });
}
Vue.prototype.$eventHub = new Vue();

Vue.prototype.datePickerModifier = datePickerModifier;
Vue.use(StoragePlugin);

router.beforeEach((to, from, next) => {
  let redirecterRetries = 0;

  initializecalendarFeedDeepLink();

  if (
    to.meta.module === moduleTypes.OVERVIEW ||
    // check meta of parent routes as well as current matched route as children do not always have meta defined
    (to.meta.module === undefined &&
      to.matched.find(matched => Boolean(matched.meta.module) === undefined) === undefined)
  ) {
    next();
  } else {
    // returns only shown modules
    const menuItems = getStoreMenuItems([moduleWidgetPlacements.DEFAULT, moduleWidgetPlacements.LEFT_MENU]);
    if (menuItems.length > 0) {
      const moduleTypes = menuItems.map(item => item.type);
      proceed(moduleTypes);
    } else {
      redirecterRetries = 0;
      redirecter();
    }

    function proceed(moduleTypes) {
      const parent = to.matched.find(matched => Boolean(matched.meta.module));
      // check meta of parent routes as well as current matched route as children do not always have meta defined
      if (moduleTypes.indexOf(to.meta.module) != -1 || (parent && moduleTypes.indexOf(parent.meta.module) != -1)) {
        next();
      } else if (store.getters[types.IS_PROFILE_LOADED]) {
        next({ path: '/overblik' });
      } else {
        next({ path: '/login' });
      }
    }

    function redirecter() {
      const menuItems = getStoreMenuItems([moduleWidgetPlacements.DEFAULT, moduleWidgetPlacements.LEFT_MENU]);

      if (redirecterRetries > 5) {
        proceed([]);
        return;
      }

      if (menuItems.length > 0) {
        const moduleTypes = menuItems.map(item => item.type);
        proceed(moduleTypes);
      } else {
        redirecterRetries++;
        setTimeout(() => {
          redirecter();
        }, 400);
      }
    }
  }

  function initializecalendarFeedDeepLink() {
    if (store.getters[types.IS_PROFILE_LOADED] === false) {
      // set information for deeplinking.
      // deeplinking must be set here to set link before redirecting to login
      calendarFeedDeepLink.setCalendarDeepLink();
    } else {
      // clear attchmentinformation on all navigation within portal
      calendarFeedDeepLink.clearCalendarFeedDeepLinkInfo();
    }
  }

  function getStoreMenuItems(placements = [moduleWidgetPlacements.DEFAULT]) {
    const storeMenuItems = store.getters[types.GET_MENU_ITEMS];
    const menuItems = [];
    for (const placement of placements) {
      const menuPlacementItems = storeMenuItems[placement] || [];
      menuItems.push(...menuPlacementItems);
    }
    return menuItems;
  }
});
new Vue({
  el: '#aula',
  store,
  router,
  render: h => h(App),
});
