<template>
  <div>
    <div class="mobile-groups-profile">
      <div
        id="dropdown-groups"
        tabindex="0"
        class="header-dropdown"
        :class="{ active: showGroupsDropdown }"
        @keydown.enter="
          !isPreviewMode
            ? toggleHeaderDropdown({
                dropdown: 'groups',
                visible: showGroupsDropdown,
              })
            : '';
          scrollToTopPage();
        "
        @click="
          !isPreviewMode
            ? toggleHeaderDropdown({
                dropdown: 'groups',
                visible: showGroupsDropdown,
              })
            : '';
          scrollToTopPage();
        "
      >
        <i class="icon-Aula_hamburger" />
      </div>
    </div>
    <transition name="slide-up">
      <div v-if="showGroupsDropdown" class="header-dropdown-menu mobile">
        <transition name="slide-mobile">
          <groups-list v-if="showGroupList" @groupClick="groupClick" @groupOpen="handleGroupOpen" />
        </transition>
        <transition name="slide-mobile">
          <user-settings-content v-if="showMobileProfileDropdown" :hide-close-icon="true" />
        </transition>
        <div class="group-profile-icons" :class="{ active: showGroupsDropdown }">
          <i
            class="icon-Aula_close"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="
              !isPreviewMode
                ? toggleHeaderDropdown({
                    dropdown: 'groups',
                    visible: showGroupsDropdown,
                  })
                : '';
              scrollToTopPage();
            "
          />
          <div class="item">
            <div class="group-profile" tabindex="0" role="button" @click="groupClick" @keydown.enter="groupClick">
              <div class="group-icon">
                <i v-if="!isChild" class="icon-Aula_group" />
                <img v-else class="menu-icon" :src="'static/icons/icon-Aula_group_child.svg'" />
              </div>
              <div class="item-title">
                {{ 'MY_GROUPS' | fromTextKey }}
              </div>
            </div>
          </div>
          <div class="item profile">
            <div
              tabindex="0"
              class="group-profile"
              @keydown.enter="
                toggleHeaderDropdown({
                  dropdown: 'mobileProfile',
                  visible: showMobileProfileDropdown,
                })
              "
              @click="
                toggleHeaderDropdown({
                  dropdown: 'mobileProfile',
                  visible: showMobileProfileDropdown,
                })
              "
            >
              <user-icon :id="profile.id" :short-name="profile.shortName" :picture-object="profile.profilePicture" />
              <div class="item-title">
                {{ 'PROFILE_LABEL' | fromTextKey }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import GroupsList from '../groups/GroupsList.vue';
import UserSettingsContent from '../users/UserSettingsContent.vue';
import { institutionRole } from '../../../shared/enums/institutionRole';

export default {
  components: {
    GroupsList,
    UserSettingsContent,
  },
  data: function () {
    return {
      showGroupList: false,
      showProfileSetting: false,
      institutionRole: institutionRole,
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      profile: types.GET_CURRENT_PROFILE,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showMobileProfileDropdown: types.GET_SHOW_MOBILE_PROFILE_DROPDOWN,
    }),
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
  },
  methods: {
    ...mapMutations({
      hideGroupDropdown: types.MUTATE_HIDE_GROUPS_DROPDOWN,
    }),
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
    }),
    groupClick() {
      this.showGroupList = !this.showGroupList;
    },
    handleGroupOpen() {
      this.showGroupList = false;
      this.hideGroupDropdown();
    },
    scrollToTopPage() {
      if (this.showGroupsDropdown) {
        document.querySelector('#aula-header').classList.add('group-dropdown-open');
      } else {
        const transitionTime = 1000;
        setTimeout(() => {
          document.querySelector('#aula-header').classList.remove('group-dropdown-open');
        }, transitionTime);
      }
      document.querySelector('html').scrollTop = 0;
      document.querySelector('body').scrollTop = 0; // this apply for ios device
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.group-profile-icons {
  display: block;
  position: absolute;
  background-color: $color-primary-light-employee;
  width: 100vw;
  height: 100dvh;
  padding-bottom: var(--menu-item-height);

  top: 0px;
  .icon-Aula_close {
    position: absolute;
    right: 20px;
    top: 15px;
    color: $color-white;
    z-index: 1000;
  }
  .item {
    display: block;
    width: 100%;
    height: 50%;
    &.profile {
      background-color: $color-primary-base-employee;
    }
    .group-profile {
      position: relative;
      top: calc(50% - 55px);
      text-align: center;
      .group-icon {
        display: block;
        margin: 0 auto;
        border-radius: 50%;
        width: 90px;
        height: 90px;
        max-height: 30vh;
        max-width: 30vh;
        font-size: 30px;
        background-color: $color-primary-dark-employee;
        i {
          display: block;
          padding-top: 25px;
          color: $color-white;
        }
        img {
          width: 60px;
          padding-top: 18px;
        }
      }
      .user {
        width: 80px;
        margin: 0 auto;
        /deep/ .user-icon {
          width: 80px;
          height: 80px;
          .user-img {
            max-width: 80px;
            max-height: 80px;
          }
          .shortname {
            font-size: 1.8em;
          }
        }
      }
      .item-title {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.2em;
        margin-top: 12px;
        color: $color-white;
      }
    }
  }
  .mobile-header {
    margin: auto;
  }
}

.header-dropdown-menu {
  height: 100vh;
  &.mobile {
    z-index: $header-dropdown-menu-mobile-z-index;
    top: 0px !important;
  }
}

//Theme styling
.theme-admin {
  .group-profile-icons {
    background: $color-primary-light-admin;
    .profile {
      background-color: $color-primary-base-admin;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-admin;
      }
    }
  }
}

.theme-guardian {
  .group-profile-icons {
    background: $color-primary-light-guardian;
    .profile {
      background-color: $color-primary-base-guardian;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-guardian;
      }
    }
  }
}

.theme-employee {
  .group-profile-icons {
    background: $color-primary-light-employee;
    .profile {
      background-color: $color-primary-base-employee;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-employee;
      }
    }
  }
}

.theme-child {
  .group-profile-icons {
    background: $color-primary-light-child;
    .profile {
      background-color: $color-primary-base-child;
    }
    .group-profile {
      .group-icon {
        background-color: $color-primary-dark-child;
      }
    }
  }
}
</style>
