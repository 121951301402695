import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../src/store/types/types';
import { subscriptionTypes } from '../enums/subscriptionTypes';
import { messageProviderKeyEnum } from '../enums/messageProviderKeyEnum';

const messageSubscriptionMixin = {
  inject: {
    getDeletedFolderId: messageProviderKeyEnum.GET_DELETED_FOLDER_ID,
  },
  props: {
    subscription: Object,
  },
  computed: {
    ...mapGetters({
      selectedThreadIds: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      selectedSubscriptionIds: types.MESSAGES_GET_CHOSEN_SUBSCRIPTION_IDS,
      selectedBundleIds: types.MESSAGES_GET_CHOSEN_BUNDLE_IDS,
      selectedSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
    }),
    deletedFolderId() {
      return this.getDeletedFolderId();
    },
  },
  methods: {
    ...mapActions({
      updateSelectedThreadIds: types.ACTION_UPDATE_SELECTED_THREAD_IDS,
      updateSelectedSubscriptionIds: types.ACTION_UPDATE_SELECTED_SUBSCRIPTION_IDS,
      updateSelectedBundleIds: types.ACTION_UPDATE_SELECTED_BUNDLE_IDS,
    }),
    ...mapMutations({
      updateBundleSubscriptionsCheckedStatusByBundleId: types.MUTATE_BUNDLE_SUBSCRIPTIONS_CHECKED_STATUS_BY_BUNDLE_ID,
      updateSubscriptionCheckedStatus: types.MUTATE_SUBSCRIPTIONS_CHECKED_STATUS,
    }),
    onCheckBoxClicked(value) {
      this.updateSubscriptionCheckedStatus({
        subscriptionId: this.subscription.subscriptionId,
        checked: value,
      });

      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE) {
        this.updateBundleIdToStore(value);
        this.updateBundleSubscriptionsCheckedStatusByBundleId({
          bundleId: this.subscription.subscriptionId,
          checked: value,
        });
        return;
      }
      this.updateSubscriptionIdToStore(value);
      this.updateThreadIdToStore(value);
    },
    updateBundleIdToStore(isChecked) {
      let selectedBundleIds = this.selectedBundleIds;
      if (isChecked) {
        if (selectedBundleIds.includes(this.subscription.subscriptionId) === false) {
          selectedBundleIds.push(this.subscription.subscriptionId);
        }
      } else {
        selectedBundleIds = selectedBundleIds.filter(item => item !== this.subscription.subscriptionId);
      }
      this.updateSelectedBundleIds(selectedBundleIds);
    },
    updateThreadIdToStore(isChecked) {
      let selectedThreadIds = this.selectedThreadIds;
      if (isChecked) {
        if (selectedThreadIds.includes(this.subscription.id) === false) {
          selectedThreadIds.push(this.subscription.id);
        }
      } else {
        selectedThreadIds = selectedThreadIds.filter(item => item !== this.subscription.id);
      }
      this.updateSelectedThreadIds(selectedThreadIds);
    },
    updateSubscriptionIdToStore(isChecked) {
      if (this.subscription.draft) {
        return;
      }
      let selectedSubscriptionIds = this.selectedSubscriptionIds;

      if (isChecked) {
        if (selectedSubscriptionIds.includes(this.subscription.subscriptionId) === false) {
          selectedSubscriptionIds.push(this.subscription.subscriptionId);
        }
      } else {
        selectedSubscriptionIds = selectedSubscriptionIds.filter(item => item !== this.subscription.subscriptionId);
      }
      this.updateSelectedSubscriptionIds(selectedSubscriptionIds);
    },
  },
};

export default messageSubscriptionMixin;
