<template>
  <div class="container">
    <b-row class="mb-4 pb-4 forward">
      <b-col cols="2" />
      <b-col cols="8" class="p-0 forwarded-note">
        <div
          v-if="message.originalRecipients != null && message.originalRecipients.length > 0"
          class="forwarded-note-header"
        >
          <i class="icon-Aula_redirect" />
          {{ 'MESSAGE_FORWARDED_NOTE' | fromTextKey }}
        </div>
        <div v-else class="forwarded-note-header">
          <i class="icon-Aula_redirect" />
          {{ 'MESSAGE_REPLY_NOTE' | fromTextKey }}
        </div>
        <template v-if="message.originalRecipients != null && message.originalRecipients.length > 0">
          {{ 'MESSAGE_FORWARD_CONVERSATION_PART_1' | fromTextKey }}
          <post-time class="message-forwarded-time" :time="originalDateTime" />
          {{ 'MESSAGE_FORWARD_CONVERSATION_PART_3' | fromTextKey }}
          {{ message.originalRecipients[0] | displayProfileNameWithMetadata }}
          <template v-if="showListOfParticipants">
            <i
              tabindex="0"
              role="button"
              :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
              class="icon-Aula_up-arrow"
              @click="showListOfParticipants = false"
              @keydown.enter="showListOfParticipants = false"
            />
            <template v-for="(recipient, index) in message.originalRecipients">
              <div v-if="index > 0" :key="index" class="text-center">
                {{ recipient | displayProfileNameWithMetadata }}
              </div>
            </template>
          </template>
          <template
            v-else-if="
              message.originalRecipients != null &&
              message.originalRecipients != undefined &&
              message.originalRecipients.length > 1
            "
          >
            {{ 'MESSAGE_FORWARD_CONVERSATION_PART_4' | fromTextKey }}
            {{ message.originalRecipients.length - 1 }}
            {{
              message.originalRecipients.length > 1
                ? 'MESSAGE_FORWARD_CONVERSATION_OTHERS'
                : 'MESSAGE_FORWARD_CONVERSATION_OTHER' | fromTextKey
            }}
            <i
              tabindex="0"
              role="button"
              :aria-label="'ARIA_LABEL_OPEN' | fromTextKey"
              class="icon-Aula_down-arrow"
              @click="showListOfParticipants = true"
              @keydown.enter="showListOfParticipants = true"
            />
          </template>
        </template>
      </b-col>
      <b-col cols="2" />
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    message: { type: Object, default: () => {} },
    listOfSeenBy: { type: Object, default: () => {} },
    originalCreator: { type: Object, default: () => {} },
    originalDateTime: { type: String, default: '' },
    shownSubscription: { type: Object, default: () => {} },
  },
  data() {
    return {
      showListOfParticipants: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.forwarded-note {
  color: $color-conversation-left;
  .forwarded-note-header {
    font-weight: bold;
  }
}

.forward {
  border-bottom: 1px solid $color-read-message;
}
</style>
