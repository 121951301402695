<template>
  <AulaButtons>
    <AulaButton variant="link" class="draft-hidden" tabindex="-1" @click.stop="onMarkImportantClicked">
      <i v-if="marked" class="icon-Aula_star_solid" />
      <i v-else class="icon-Aula_star" />
    </AulaButton>
    <AulaButton variant="link" class="draft-hidden" tabindex="-1" @click.stop="onMarkAsReadClicked">
      <i v-if="read" class="icon-Aula_envelope_closed" />
      <i v-else class="icon-Aula_envelope_open" />
    </AulaButton>
    <AulaButton variant="link" class="draft-hidden" tabindex="-1" @click.stop="onMoveToFolderClicked">
      <i class="icon-Aula_folder" />
    </AulaButton>
    <AulaButton variant="link" tabindex="-1" @click.stop="onDeleteClicked">
      <i class="icon-Aula_bin" />
    </AulaButton>
  </AulaButtons>
</template>
<script>
import AulaButtons from '../../components/AulaButtons';
import AulaButton from '../../components/AulaButton';
export default {
  components: { AulaButton, AulaButtons },
  props: {
    marked: { type: Boolean, default: false },
    read: { type: Boolean, default: false },
  },
  methods: {
    onMarkImportantClicked() {
      this.$emit('onMarkImportantClicked');
    },
    onMarkAsReadClicked() {
      this.$emit('onMarkAsReadClicked');
    },
    onMoveToFolderClicked() {
      this.$emit('onMoveToFolderClicked');
    },
    onDeleteClicked() {
      this.$emit('onDeleteClicked');
    },
  },
};
</script>
<style scoped lang="scss">
.draft {
  .draft-hidden {
    visibility: hidden;
  }
}

button i {
  font-size: var(--thread-actions-icon-size, 1rem);
  vertical-align: middle;
  color: var(--color-primary-base);
  &.icon-Aula_star_solid {
    color: var(--color-alert);
  }
}
</style>
