<template>
  <div
    v-if="Object.keys(profile).length > 0"
    v-click-outside="hideDropdown"
    class="profile-settings filter-row"
    :title="'MY_PROFILE_LABEL_CHILD' | fromTextKey"
  >
    <div
      id="dropdown-profile-settings"
      class="header-dropdown"
      :class="{ active: showProfileDropdown }"
      :aria-label="'ARIA_LABEL_MY_PROFILE' | fromTextKey"
      tabindex="0"
      role="button"
      @click="
        !isPreviewMode
          ? toggleHeaderDropdown({
              dropdown: 'profile',
              visible: showProfileDropdown,
            })
          : ''
      "
      @keyup.enter="
        !isPreviewMode
          ? toggleHeaderDropdown({
              dropdown: 'profile',
              visible: showProfileDropdown,
            })
          : ''
      "
    >
      <Avatar :profile="profile" />
    </div>
    <transition
      name="slide-fade"
      :leave-active-class="isClickedCurrentTab ? 'slide-fade-leave-active' : 'slide-fade-leave-active-hide'"
      :leave-to-class="isClickedCurrentTab ? 'slide-fade-leave-to' : 'leave-to'"
    >
      <div v-show="showProfileDropdown" class="header-dropdown-menu" role="dialog">
        <user-settings-content />
      </div>
    </transition>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import UserSettingsContent from './UserSettingsContent.vue';
import Avatar from '../../../shared/components/Avatar.vue';

export default {
  components: {
    Avatar,
    UserSettingsContent,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      profile: types.GET_CURRENT_PROFILE,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showSearchDropdown: types.GET_SHOW_SEARCH_DROPDOWN,
      showProfileDropdown: types.GET_SHOW_PROFILE_DROPDOWN,
    }),
    isClickedCurrentTab: function () {
      return this.showProfileDropdown || (!this.showGroupsDropdown && !this.showSearchDropdown);
    },
  },
  methods: {
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
    }),
    hideDropdown() {
      this.$store.commit(types.MUTATE_HIDE_PROFILE_DROPDOWN);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';

.header-dropdown {
  /deep/ .user-icon {
    background-color: $color-white !important;
    .shortname {
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
    }
  }
}

.menu-icon {
  /deep/ .user-icon {
    .early-student & {
      border: solid 3px $color-white;
    }
  }
}

.small-text {
  text-transform: uppercase;
  .early-student & {
    color: #fff !important;
    text-transform: capitalize;
    font-family: Baloo;
    font-weight: normal;
    font-size: 15px !important;
  }
}

.header-dropdown-menu {
  .aula-modal-open & {
    overflow: initial !important;
    z-index: 1 !important;
  }
}
</style>
