<template>
  <div class="aula-gallery-container">
    <template v-if="!showAlbumDetailForUpload">
      <gallery-toolbar
        v-if="parent != parentTypes.UPLOAD && !selectMode"
        :parent="parent"
        @activateDownloadAlbums="activateDownloadAlbums()"
      />
      <download-toolbar
        v-if="selectMode && (parent == parentTypes.GALLERY || parent == parentTypes.GROUP)"
        :amount-of-selected="albums.filter(album => album.selected).length"
        :amount-text="
          albums.filter(album => album.selected).length === 1
            ? 'GALLERY_TOOLBAR_DOWNLOAD_SINGLE_AMOUNT'
            : 'GALLERY_TOOLBAR_DOWNLOAD_AMOUNT'
        "
        :is-downloading="isDownloading"
        @cancelDownload="selectMode = false"
        @activateDownload="activateDownloadAlbums"
      />
      <div class="gallery-container page-layout-padding">
        <Alerts
          v-if="parent === parentTypes.GALLERY"
          class="mb-0 mb-lg-4 p-2 p-lg-0"
          :filtered-areas="notificationAreas"
        />
        <b-row class="gallery-header">
          <b-col class="d-flex flex-row justify-content-start">
            <h1 v-if="!isMobile" class="left m-0">{{ 'GALLERY_TITLE' | fromTextKey }}</h1>
          </b-col>
          <b-col class="d-flex flex-row justify-content-end">
            <b-dropdown ref="sorter" class="dropdown-select align-self-end sorter" right>
              <template #button-content>
                <div class="sort ml-2 mb-1 padding-bottom" />
                {{ filterName }}
                <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
              </template>
              <template v-for="filter in filterConfigOptions">
                <b-dropdown-item :key="filter.value" :active="isFilterSelected(filter)" @click="doFilter(filter.value)">
                  <span v-if="isFilterSelected(filter)" class="sr-only"
                    >{{ 'ARIA_GALLERY_FILTER_SELECTED' | fromTextKey }} </span
                  >{{ filter.name | fromTextKey }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
            <b-dropdown ref="sorter" class="dropdown-select align-self-end sorter" toggle-class="sorter" right>
              <template #button-content>
                <div class="sort ml-2 mb-1" :class="orderDirection" />
                {{ sortName }}
                <i class="icon icon-Aula_down-arrow" aria-hidden="true" />
              </template>
              <template v-for="sort in sortConfigOptions">
                <b-dropdown-item
                  :key="sort.value"
                  :class="sort.value === sortOn ? orderDirection : ''"
                  @click="doSort(sort.value, sort.name)"
                >
                  {{ sort.name | fromTextKey }}
                </b-dropdown-item>
              </template>
            </b-dropdown>
          </b-col>
        </b-row>
        <hr v-if="!isMobile" />
        <div v-if="selectMode && parent !== parentTypes.UPLOAD" class="gallery-select">
          <b-form-checkbox v-model="selectAll" class="accept" @input="selectAllAlbum">
            {{ 'GALLERY_SELECT_ALL' | fromTextKey }}
          </b-form-checkbox>
        </div>
        <ObservingContainer :options="observerOptions">
          <b-row class="gallery-body">
            <b-col
              v-for="album in albums"
              :id="`album-${album.id}`"
              :key="album.id"
              cols="6"
              md="4"
              xl="3"
              class="album-item mb-3"
              tabindex="0"
              role="button"
              :class="{ 'p-3': selectMode }"
              :aria-label="album.title"
              :title="album.title"
              @click="!selectMode || parent == parentTypes.UPLOAD ? openAlbum(album) : ''"
              @keydown.enter="!selectMode || parent == parentTypes.UPLOAD ? openAlbum(album) : ''"
            >
              <b-row>
                <b-col v-if="selectMode && album.id != null" cols="1">
                  <b-form-checkbox
                    v-model="album.selected"
                    class="accept"
                    @click.native.stop
                    @input="parent == parentTypes.UPLOAD ? emitSelectedMediaForEditor() : ''"
                  />
                </b-col>
                <b-col>
                  <div class="gallery-thumbnail" :class="parent">
                    <template v-if="parent !== parentTypes.GROUP">
                      <badge-notification
                        v-if="album.id == null"
                        :show-exclamation-icon-instead-of-amount="calculateBadges(album.id) > 0"
                        :border="true"
                      />
                      <badge-notification v-else :amount="calculateBadges(album.id)" :border="true" />
                    </template>
                    <AlbumCard :album="album" />
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <aula-spinner v-if="isLoading" />
            </b-col>
          </b-row>
          <ObservedTarget v-if="isObservable" @onIntersecting="onBottomReached" />
        </ObservingContainer>
      </div>
    </template>
    <album-detail
      v-if="parent == parentTypes.UPLOAD && showAlbumDetailForUpload"
      :parent="parent"
      :my-media="showMyMediaAlbumForUpload"
      :album-id="selectedAlbumId"
      :institution-profile-ids-filter="institutionProfileIdsFilter"
      @addMediaFromGallery="$emit('addMediaFromGallery')"
      @selectedMediaUpdated="$emit('selectedMediaUpdated', $event)"
      @hideAlbumDetailForEditor="handleAlbumDetailHidden"
    />
    <toastr ref="downloadToastr" variant="success" :show-icon="true" icon="icon-Aula_check">
      {{ 'GALLERY_DOWNLOAD_SUCCESS' | fromTextKey }}
    </toastr>
    <create-album
      v-if="
        $route.path.includes('galleri/opret') ||
        $route.path.includes('galleri/tilfoej') ||
        $route.path.includes('galleri/rediger')
      "
      :parent="parent"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import axios from 'axios';
import GalleryToolbar from './GalleryToolbar';
import BadgeNotification from '../../../shared/components/BadgeNotification.vue';
import AlbumDetail from './AlbumDetail';
import DownloadToolbar from './DownloadToolbar';
import CreateAlbum from './CreateAlbum';
import { notificationEventTypes } from '../../../shared/enums/notificationEventTypes';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'jszip/vendor/FileSaver';
import { orderDirections } from '../../../shared/enums/orderDirections';
import { albumSortType } from '../../../shared/enums/albumSortType';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import { albumFilterType } from '../../../shared/enums/albumFilterType';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import AlbumCard from './AlbumCard.vue';
import ObservingContainer from '../../../shared/libs/intersection-observer/components/Container.vue';
import ObservedTarget from '../../../shared/libs/intersection-observer/components/Target.vue';
import Alerts from '../../../shared/components/Alerts.vue';
import { notificationAreas } from '../../../shared/enums/notificationAreas';

export default {
  components: {
    Alerts,
    ObservedTarget,
    ObservingContainer,
    AlbumCard,
    GalleryToolbar,
    AlbumDetail,
    BadgeNotification,
    CreateAlbum,
    DownloadToolbar,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.isProfileLoaded || vm.isPreviewMode) {
        return;
      }
      if (['albumDetails', 'myMedias', 'group-album-details'].includes(from.name) && !vm.hasMediaChanged) {
        return;
      }
      vm.refresh();
    });
  },
  props: {
    parent: { type: String, default: parentTypes.GALLERY },
    institutionProfileIdsFilter: { type: Array, required: false, default: null },
  },
  data: function () {
    return {
      mobilePageComponent: 'album',
      selectAll: false,
      isLoading: false,
      selectMode: false,
      currentIndex: -1,
      pageSize: 12,
      listSelectedAlbum: [],
      showAlbumDetailForUpload: false,
      showMyMediaAlbumForUpload: false,
      selectedAlbumId: '',
      isDownloading: false,
      defaultConfigSections: [
        {
          name: 'GALLERY_ALBUM',
          filter: 'all',
          portalRoles: [portalRoles.EMPLOYEE, portalRoles.CHILD, portalRoles.GUARDIAN],
        },
      ],
      filterConfigOptions: [
        {
          value: albumFilterType.ALL,
          name: 'GALLERY_FILTER_ALL',
        },
        {
          value: albumFilterType.MY_ALBUMS,
          name: 'MEDIA_FILTER_MY_MEDIA_ALBUMS',
        },
      ],
      parentTypes,
      albumSortType,
      albumFilterType,
      originalProfilePortalRole: null,
      loadGalleryCancelTokenSource: null,
      isFiltered: false,
      isSorted: false,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      images: types.GET_IMAGES,
      profile: types.GET_CURRENT_PROFILE,
      date: types.GET_DATE,
      albums: types.GET_ALBUMS,
      moreAlbumsExist: types.GET_MORE_ALBUMS_EXIST,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeGroup: types.GET_ACTIVE_GROUP,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      children: types.GET_CHILDREN,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      notifications: types.GET_NOTIFICATIONS,
      hasMediaChanged: types.GET_HAS_MEDIA_CHANGED,
      activeAlbum: types.GET_ACTIVE_ALBUM,
      filterBy: types.GET_GALLERY_FILTER_BY,
      sortOn: types.GET_GALLERY_SORT_ON,
      orderDirection: types.GET_GALLERY_ORDER_DIRECTION,
    }),
    filterName() {
      if (!this.isFiltered) {
        return this.$options.filters.fromTextKey('GALLERY_FILTER');
      }
      const selectedFilter = this.filterConfigOptions.find(option => option.value === this.filterBy);
      return this.$options.filters.fromTextKey(selectedFilter?.name);
    },
    sortName() {
      if (!this.isSorted) {
        return this.$options.filters.fromTextKey('GALLERY_SORT');
      }
      const selectedSort = this.sortConfigOptions.find(option => option.value === this.sortOn);
      return this.$options.filters.fromTextKey(selectedSort?.name);
    },
    notificationAreas() {
      return [notificationAreas.GALLERY];
    },
    observerOptions() {
      return { rootMargin: '50%' };
    },
    isObservable() {
      return this.albums.length > 0;
    },
    isProfileSwitched() {
      return this.originalProfilePortalRole != this.profile.role;
    },
    getFilterInstProfileIds() {
      if (this.institutionProfileIdsFilter !== null) {
        return this.institutionProfileIdsFilter;
      }
      if (this.profile.role === portalRoles.OTP || this.profile.role === portalRoles.GUARDIAN) {
        return this.activeChildren;
      }
      return this.institutions
        .filter(institution => this.activeInstitutions.includes(institution.institutionCode))
        .map(institution => institution.institutionProfileId);
    },
    sortConfigOptions() {
      const sortConfigOptions = [
        {
          value: albumSortType.CREATED_AT,
          name: 'SORT_BY_CREATED_DATE',
        },
        {
          value: albumSortType.TITLE,
          name: 'SORT_BY_ALBUM_NAME',
        },
      ];
      if (!Cookie.Read('fromAdminGroup')) {
        sortConfigOptions.unshift({
          value: albumSortType.MEDIA_CREATED_AT,
          name: 'SORT_BY_NEWEST_MEDIA',
        });
      }
      return sortConfigOptions;
    },
  },
  watch: {
    activeInstitutions: function () {
      if (!this.isProfileSwitched) {
        this.refresh();
      }
    },
    activeChildren: function () {
      if (!this.isProfileSwitched) {
        this.refresh();
      }
    },
  },
  created() {
    this.originalProfilePortalRole = this.profile.role;
  },
  mounted() {
    this.initializeAlbums();
    this.scrollToSelectedAlbum();
  },
  methods: {
    ...mapActions({
      downloadZipFile: types.DOWNLOAD_ZIP_FILE,
      getAlbums: types.ACTION_GET_ALBUMS,
      getAlbumsAdmin: types.ACTION_GET_ALBUMS_ADMIN,
      loadAlbumMedia: types.ACTION_GET_ALBUM_DETAILS,
      loadAlbumMediaAdmin: types.ACTION_GET_ALBUM_DETAILS_ADMIN,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
    }),
    ...mapMutations({
      showErrorText: types.MUTATE_ERROR_TEXT,
      resetAlbums: types.MUTATE_RESET_ALBUMS,
      setHasMediaChanged: types.MUTATE_HAS_MEDIA_CHANGED,
      mutateFilterBy: types.MUTATE_GALLERY_FILTER_BY,
      mutateSortOn: types.MUTATE_GALLERY_SORT_ON,
      mutateOrderDirection: types.MUTATE_GALLERY_ORDER_DIRECTION,
    }),
    handleAlbumDetailHidden() {
      this.showAlbumDetailForUpload = false;
      this.scrollToSelectedAlbum();
    },
    mutateDefaultParams() {
      const sortOn = Cookie.Read('fromAdminGroup') ? albumSortType.CREATED_AT : albumSortType.MEDIA_CREATED_AT;
      const orderDirection = Cookie.Read('fromAdminGroup') ? orderDirections.ASCENDING : orderDirections.DESCENDING;
      this.mutateFilterBy(albumFilterType.ALL);
      this.mutateSortOn(sortOn);
      this.mutateOrderDirection(orderDirection);
    },
    initializeAlbums() {
      this.currentIndex = this.albums.length - 1;
      this.selectMode = this.parent === parentTypes.UPLOAD;
      const isRefreshed = this.selectMode || this.albums.length === 0;
      if (isRefreshed) {
        this.mutateDefaultParams();
        this.refresh();
      }
      const isDefaultSort =
        (Cookie.Read('fromAdminGroup') && this.sortOn === albumSortType.CREATED_AT) ||
        this.sortOn === albumSortType.MEDIA_CREATED_AT;
      const isDefaultOrder =
        (Cookie.Read('fromAdminGroup') && this.orderDirection === orderDirections.ASCENDING) ||
        this.orderDirection === orderDirections.DESCENDING;
      this.isFiltered = this.filterBy !== albumFilterType.ALL;
      this.isSorted = !isDefaultSort || !isDefaultOrder;
    },
    scrollToSelectedAlbum() {
      if (!this.activeAlbum) {
        return;
      }
      this.$nextTick(() => {
        this.$el.querySelector(`#album-${this.activeAlbum.id}`)?.scrollIntoView({ block: 'center' });
      });
    },
    onBottomReached() {
      if (this.isLoading || !this.moreAlbumsExist) {
        return;
      }
      this.loadAlbums();
    },
    calculateBadges(albumId) {
      return this.notifications.filter(
        notification =>
          (notification.albumId === albumId &&
            notification.notificationEventType !== notificationEventTypes.MEDIA_ADDED_TO_ALBUM) ||
          (albumId === null &&
            notification.notificationEventType === notificationEventTypes.TAGGED_IN_MEDIA &&
            notification.notificationType === notificationTypes.BADGE)
      ).length;
    },
    openAlbum(album) {
      if (this.parent === parentTypes.GALLERY) {
        if (!album.id) {
          this.removeGalleryNotification([
            {
              type: notificationEventTypes.TAGGED_IN_MEDIA,
              albumId: null,
            },
          ]);
          if (album.regardingInstitutionProfileId != null) {
            this.$router.push({
              name: 'institutionProfileMedias',
              params: {
                institutionProfileId: album.regardingInstitutionProfileId,
              },
            });
          } else {
            this.$router.push({ name: 'myMedias' });
          }
          return;
        } else {
          this.removeGalleryNotification([
            {
              type: notificationEventTypes.TAGGED_IN_MEDIA,
              albumId: album.id,
            },
            {
              type: notificationEventTypes.ALBUM_SHARED,
              albumId: album.id,
            },
            {
              type: notificationEventTypes.NEW_MEDIA_IN_ALBUM,
              albumId: album.id,
            },
            {
              type: notificationEventTypes.MEDIA_ADDED_TO_ALBUM,
              albumId: album.id,
            },
          ]);
          this.$router.push({
            name: 'albumDetails',
            params: { albumId: album.id },
          });
        }
      } else if (this.parent === parentTypes.GROUP) {
        this.$router.push({
          name: 'group-album-details',
          params: { albumId: album.id },
        });
      } else {
        if (album.id == null) {
          this.showMyMediaAlbumForUpload = true;
          this.selectedAlbumId = null;
        } else {
          this.showMyMediaAlbumForUpload = false;
          this.selectedAlbumId = album.id;
        }
        this.showAlbumDetailForUpload = true;
      }
    },
    getBinaryContent(url) {
      return new Promise(function (resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    },
    async activateDownloadAlbums() {
      if (!this.selectMode) {
        this.initSelectMode();
        return;
      }
      if (!this.albums.some(album => album.id != null && album.selected)) {
        return;
      }
      this.isDownloading = true;
      await this.generateAsyncZip();
      this.isDownloading = false;
      this.selectMode = false;
    },
    async generateAsyncZip() {
      const zip = new JSZip();
      const selectedAlbums = this.albums.filter(album => album.selected && album.id != null);
      for (const album of selectedAlbums) {
        const albumMedia = await this.getAlbumMedia(album.id);
        for (const media of albumMedia) {
          try {
            const currentFile = await this.getBinaryContent(media.file.url);
            zip.file(album.title + '/' + media.file.name, currentFile, {
              binary: true,
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
        }
      }
      this.compressDownloadFiles(zip);
    },
    async getAlbumMedia(albumId) {
      const albumMedia = [];
      let mediaCount = 0;
      const params = {
        albumId: albumId,
        index: 0,
        limit: 10,
      };
      let loadAlbumMediaAction = this.loadAlbumMedia;
      if (Cookie.Read('fromAdminGroup')) {
        loadAlbumMediaAction = this.loadAlbumMediaAdmin;
      }
      do {
        const albumMediaResponse = await loadAlbumMediaAction(params);
        const mediaResults = albumMediaResponse.results;
        albumMedia.push(...mediaResults);
        params.index = albumMedia.length;
        mediaCount = albumMediaResponse.mediaCount;
      } while (params.index < mediaCount);
      return albumMedia;
    },
    compressDownloadFiles(zip) {
      zip
        .generateAsync({ type: 'blob' })
        .then(blob => {
          saveAs(blob, 'download.zip');
          this.$refs.downloadToastr.show();
        })
        .catch(() => {
          this.showErrorText('GALLERY_DOWNLOAD_ERROR');
        });
    },
    initSelectMode() {
      this.selectMode = true;
      this.selectAll = false;
      this.selectAllAlbum();
    },
    selectAllAlbum() {
      for (const album of this.albums) {
        if (this.selectAll) {
          album.selected = true;
        } else {
          album.selected = false;
        }
      }
    },
    // These 3 methods to solve an issue with pagination in AULA-34760,
    getRequestLimit() {
      // AULA-34760: Get one extra album to check if more albums exist,
      // the extra album has already been added by the Back-end in the 1st page (album for tagged media)
      let pageSize = this.pageSize;
      if (this.currentIndex > -1 || this.parent === parentTypes.GROUP) {
        pageSize++;
      }
      return pageSize;
    },
    getRequestOffset() {
      if (this.parent !== parentTypes.GALLERY) {
        return this.currentIndex + 1;
      }
      return this.currentIndex === -1 ? 0 : this.currentIndex;
    },
    increaseRequestOffsetValue() {
      this.currentIndex = this.currentIndex + this.pageSize;
    },
    loadAlbums: function () {
      if (this.loadGalleryCancelTokenSource != null) {
        this.loadGalleryCancelTokenSource.cancel();
        this.resetLoadGalleryCancelTokenSource();
      }
      this.isLoading = true;

      let getAlbumFunction = this.getAlbums;
      if (Cookie.Read('fromAdminGroup')) {
        getAlbumFunction = this.getAlbumsAdmin;
      }
      getAlbumFunction(this.getAlbumHttpConfigurations()).then(
        () => {
          this.isLoading = false;
          this.resetLoadGalleryCancelTokenSource();
          this.increaseRequestOffsetValue();
        },
        () => null
      );
    },
    refresh() {
      this.currentIndex = -1;
      this.resetAlbums();
      this.setHasMediaChanged(false);
      this.loadAlbums();
    },
    doSort(sort, sortOn) {
      this.sortName = sortOn;
      this.isSorted = true;
      if (this.sortOn === sort) {
        const orderDirection =
          this.orderDirection === orderDirections.ASCENDING ? orderDirections.DESCENDING : orderDirections.ASCENDING;
        this.mutateOrderDirection(orderDirection);
      } else if (sort === albumSortType.MEDIA_CREATED_AT) {
        this.mutateSortOn(sort);
        this.mutateOrderDirection(orderDirections.DESCENDING);
      } else {
        this.mutateSortOn(sort);
        this.mutateOrderDirection(orderDirections.ASCENDING);
      }
      this.refresh();
    },
    doFilter(filterBy) {
      this.mutateFilterBy(filterBy);
      this.isFiltered = true;
      this.refresh();
    },
    getAlbumHttpConfigurations() {
      this.createSourceForLoadGalleryCancelTokenSource();
      const config = {
        params: {
          index: this.getRequestOffset(),
          limit: this.getRequestLimit(),
          sortOn: this.sortOn,
          orderDirection: this.orderDirection,
          filterBy: this.filterBy,
        },
        cancelToken: this.loadGalleryCancelTokenSource.token,
      };
      if (this.parent === parentTypes.GROUP) {
        config.params.groupId = this.$route.params.groupId;
      } else {
        config.params.filterInstProfileIds = this.getFilterInstProfileIds;
      }
      return config;
    },
    emitSelectedMediaForEditor() {
      const selected = [];
      for (const album of this.albums) {
        if (album.selected) {
          selected.push(album);
        }
      }
      this.$emit('selectedAlbumsUpdated', selected);
    },
    removeGalleryNotification(removeNotifications) {
      let notificationsToRemove = [];
      for (const removeNotification of removeNotifications) {
        const notificationsForEachTpye = this.notifications.filter(
          notification =>
            notification.notificationEventType == removeNotification.type &&
            notification.albumId == removeNotification.albumId
        );
        if (notificationsForEachTpye.length > 0) {
          notificationsToRemove = [...notificationsToRemove, ...notificationsForEachTpye];
        }
      }
      if (notificationsToRemove.length > 0) {
        const notifications = notificationsToRemove.map(function (notification) {
          return {
            notificationId: notification.notificationId,
            institutionProfileId: notification.institutionProfileId,
          };
        });
        if (notifications.length > 0) {
          this.deleteNotifications({ notifications: notifications });
        }
      }
    },
    resetLoadGalleryCancelTokenSource() {
      this.loadGalleryCancelTokenSource = null;
    },
    createSourceForLoadGalleryCancelTokenSource() {
      this.loadGalleryCancelTokenSource = axios.CancelToken.source();
    },
    isFilterSelected(filter) {
      return filter.value === this.filterBy;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/gallery/gallery';
.icon-Album {
  font-size: 15px;
}
.gallery-thumbnail /deep/ {
  .badge-notification {
    left: 5px;
    top: 5px;
    z-index: 1;
  }
}
</style>
