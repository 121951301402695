<template>
  <div ref="container" v-bind="{ ...$attrs }" class="resizable-container">
    <div v-if="rightResizer" class="resizer resizer-right" @mousedown="onRightResizerMouseDown" />
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    defaultWidth: { type: String, default: '' },
    rightResizer: { type: Boolean, default: true },
  },
  data() {
    return {
      initialPositionX: null,
      containerClientX: null,
    };
  },
  watch: {
    defaultWidth() {
      this.$refs.container.style.width = this.defaultWidth;
    },
  },
  mounted() {
    this.$refs.container.style.width = this.defaultWidth;
  },
  methods: {
    onRightResizerMouseDown(event) {
      this.initialPositionX = event.clientX;
      this.containerClientX = this.$refs.container.clientWidth;

      document.addEventListener('mousemove', this.mouseMoveHandler);
      document.addEventListener('mouseup', this.mouseUpHandler);
      this.setIframePointerEvents('none');
    },
    mouseUpHandler(event) {
      this.pauseEvent(event);
      document.removeEventListener('mousemove', this.mouseMoveHandler);
      document.removeEventListener('mouseup', this.mouseUpHandler);
      this.setIframePointerEvents(null);
      this.$emit('onResized', this.$refs.container.style.width);
    },
    mouseMoveHandler(event) {
      this.pauseEvent(event);
      const dx = event.clientX - this.initialPositionX;
      this.$refs.container.style.width = `${this.containerClientX + dx}px`;
    },
    pauseEvent(e) {
      e.stopPropagation();
      e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;
      return false;
    },
    setIframePointerEvents(value) {
      const iframeElements = document.querySelectorAll('iframe');
      iframeElements.forEach(element => {
        element.style.pointerEvents = value;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.resizable-container {
  position: relative;
}

.resizer {
  position: absolute;
  width: 15px;
  z-index: var(--resizer-z-index, 1);
  cursor: ew-resize;

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background-color: var(--color-grey-base);
    transition: width 200ms;
  }

  &:is(:hover, :focus)::before {
    width: 6px;
    left: 0;
  }

  &.resizer-right {
    top: 0;
    right: -15px;
    height: 100%;
  }
}
</style>
