<template>
  <aula-privacy-policy v-if="$route.path.includes('openPrivacy')" />
  <Unsubscribe v-else-if="$route.path.includes('unsubscribe')" />
  <aula-login v-else-if="$route.path.includes('login')" />
  <aula-widget-mobile v-else-if="$route.path.includes('widgetMobile')" />
  <aula-noaccess v-else-if="$route.path.includes('noaccess')" />
  <aula-stepupfailure v-else-if="$route.path.includes('stepupfailure')" />
  <aula-onboarding v-else-if="$route.path.includes('onboarding')" />
  <aula-choose-portal-role v-else-if="$route.path.includes('choosePortalRole')" />
  <div v-else-if="isUserLoggedIn" id="aula-content" ref="aula-content" aria-atomic="true" class="aula-content">
    <input id="focus-element" type="hidden" name="focus-element" aria-hidden="true" tabindex="-1" />
    <div v-if="isImpersonationMode" class="impersonation-header">
      <b-button size="sm" @click="endImpersonationMode">
        {{ 'END_IMPERSONATION_LABEL' | fromTextKey }}
      </b-button>
      <h1>{{ 'IMPERSONATION_WARNING' | fromTextKey }}</h1>
      <h2>{{ profile.firstName }} {{ profile.lastName }}</h2>
    </div>
    <aula-header v-if="$route.path != '/login'" role="banner" />
    <aula-menu v-if="$route.path != '/login'" role="navigation" />
    <div v-if="Object.keys(group).length === 0" class="content-wrapper">
      <alerts v-if="profile.type == 'parent' || profile.type == 'teacher'" />
      <main role="main">
        <router-view />
      </main>
      <aside v-if="$route.matched && $route.matched[0] && $route.matched[0].components.aside">
        <router-view name="aside" />
      </aside>
    </div>
    <div class="main-toastr-container">
      <toastr ref="errorToastr" variant="warning" icon-font-size="1em" circle-background="white">
        {{ 'ERROR_TOASTR_TEXT' | fromTextKey }}{{ errorText | fromTextKey }}
      </toastr>
      <toastr ref="successToastr" variant="success" :show-icon="true" icon="icon-Aula_check">
        {{ successText | fromTextKey }}
      </toastr>
    </div>
    <DebugApiErrorModal />
    <aula-modals />
    <group-page v-if="$route.path.includes('gruppe')" />
    <step-up-notification />
    <session-timeout-warning />
    <portal-target v-for="modalId in openModals" :key="modalId" :name="'modal-' + modalId" />
    <portal-target name="fixedElement" />
    <portal-target name="multiButtonMobile" />
    <aula-modal
      ref="stepupUserMismatchModal"
      :disable-portal="true"
      :disable-close="true"
      ok-text="YES"
      @hidden="cancelStepUpUserMismatch"
      @cancelClicked="cancelStepUpUserMismatch"
      @okClicked="retryStepUp"
    >
      {{ 'WARNING_UNILOGIN_MISMATCH' | fromTextKey }}<br /><br />
      <ul>
        <li>{{ 'WARNING_UNILOGIN_MISMATCH_REASON_1' | fromTextKey }}</li>
        <li>{{ 'WARNING_UNILOGIN_MISMATCH_REASON_2' | fromTextKey }}</li>
        <li>{{ 'WARNING_UNILOGIN_MISMATCH_REASON_3' | fromTextKey }}</li>
      </ul>
      {{ 'WARNING_UNILOGIN_MISMATCH_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import Login from '../shared/components/login/Login.vue';
import Widget from './components/widgets/WidgetMobile.vue';
import NoAccess from '../shared/components/login/NoAccess.vue';
import StepUpFailure from '../shared/components/stepup/StepUpFailure.vue';
import Onboarding from './components/onboarding/Onboarding.vue';
import Header from './components/shared/Header.vue';
import Menu from './components/shared/Menu.vue';
import Modals from './components/shared/Modals.vue';
import Alerts from '../shared/components/Alerts.vue';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { mapActions, mapMutations } from 'vuex';
import { types } from './store/types/types';
import AulaModals from './components/shared/Modals';
import StepUpNotification from './components/shared/StepUpNotification';
import SessionTimeOutWarning from '../shared/components/SessionTimeOutWarning';
import $ from 'jquery';
import { portalRoles } from '../shared/enums/portalRoles';
import { institutionRole } from '../shared/enums/institutionRole';
import { Cookie } from '../shared/assets/plugins/cookie';
import { csrfprotector_init } from '../shared/security/csrfprotector';
import { parentTypes } from '../shared/enums/parentTypes.ts';
import OpenPrivacyPolicy from './components/users/OpenPrivacyPolicy';
import { redirectToCalendarFeedDeepLink } from '../shared/assets/plugins/calendarFeedDeepLink';
import ChoosePortalRole from '../shared/components/login/ChoosePortalRole';
import { onboardingStatusEnum } from '../shared/enums/onboardingStatusEnum';
import Unsubscribe from './components/Unsubscribe/Unsubscribe';
import DebugApiErrorModal from '../shared/components/DebugApiErrorModal.vue';

export default {
  data: function () {
    return {
      portalRoles: portalRoles,
      isUserLoggedIn: false,
      institutionRole: institutionRole,
    };
  },
  computed: {
    ...mapGetters({
      onboardingStatus: types.IS_ONBOARDING_NEEDED,
      onboardingSteps: types.GET_ONBOARDING_STEPS,
      group: types.GET_ACTIVE_GROUP,
      isMobile: types.GET_IS_MOBILE,
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isImpersonationMode: types.GET_IS_IMPERSONATION_MODE,
      profile: types.GET_CURRENT_PROFILE,
      notifications: types.GET_NOTIFICATIONS,
      clientHeight: types.GET_CLIENT_HEIGHT,
      clientWidth: types.GET_CLIENT_WIDTH,
      errorText: types.GET_ERROR_TEXT,
      successText: types.GET_SUCCESS_TEXT,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      getAdditionalData: types.GET_ADDITIONAL_DATA,
      getConsentResponses: types.GET_MYPROFILE_PENDING_CONSENTS,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      openModals: types.GET_OPEN_MODALS,
    }),
    themeClass() {
      return this.profile != null && this.profile.role != portalRoles.OTP ? this.profile.role : portalRoles.GUARDIAN;
    },
  },
  created() {
    if (
      this.$route.path.includes('openPrivacy') ||
      this.$route.path.toLowerCase().includes('chooseportalrole') ||
      this.$route.path.toLowerCase().includes('unsubscribe')
    ) {
      return;
    }
    if (
      !this.$route.path.includes('noaccess') &&
      !this.$route.path.includes('stepupfailure') &&
      !this.$route.path.includes('widgetMobile')
    ) {
      if (Cookie.Read('isImpersonationMode')) {
        this.setImpersonationMode(true);
      }
      if (Cookie.Read('isImpersonationMode') && !Cookie.Read('hasLoadedImpersonateProfile')) {
        this.impersonationLogin();
      } else {
        // LOGIN
        if (this.$route.query.isPreviewMode == 'true') {
          this.setPreviewMode(true);
          document.title = Vue.filter('fromTextKey')('PREVIEW_MODE_PAGE_TITLE');
        }
        this.loadProfilesByLogin().then(rsp => {
          if (
            rsp != null &&
            rsp.response != null &&
            rsp.response.data != null &&
            rsp.response.data.status != null &&
            (rsp.response.data.status.code == 448 ||
              rsp.response.data.status.code == 451 ||
              rsp.response.data.status.code == 452)
          ) {
            switch (rsp.response.data.status.code) {
              case 448:
                this.$router.push({ name: 'login' });
                break;
              case 451:
                this.$router.push({
                  name: 'NoAccess',
                  query: { type: 'accessNotGrantedYet' },
                });
                break;
              case 452:
                this.$router.push({
                  name: 'NoAccess',
                  query: { type: 'deactivatedUser' },
                });
                break;
            }
          } else {
            if (this.isPreviewMode) {
              // PREVIEW
              this.previewLogin();
            } else {
              // STANDARD
              this.standardLogin();
            }
          }
        });
      }
      this.setInitialSettings();
    } else if (this.$route.path.includes('widgetMobile')) {
      this.loadProfilesByLogin().then(() => {
        this.getProfileContext({ parent: parentTypes.PORTAL }).then(() =>
          Promise.all([
            this.calculateMenuItems(),
            [this.portalRoles.GUARDIAN, this.portalRoles.CHILD].includes(this.profile.role)
              ? this.getProfileConsent()
              : null,
          ])
        );
      });
    }
  },
  methods: {
    ...mapActions({
      checkIsOnboardingNeeded: types.CHECK_IS_ONBOARDING_NEEDED,
      setPreviewMode: types.SET_IS_PREVIEW_MODE,
      loadPreviewModulesWidgetsConfig: types.LOAD_PREVIEW_MODULES_WIDGETS_CONFIG,
      calculateMenuItems: types.CALCULATE_MENU_ITEMS,
      getProfileContext: types.ACTION_GET_PROFILE_CONTEXT,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      initPermissions: types.INIT_PERMISSIONS,
      getProfileConsent: types.ACTION_GET_CONSENTS_RESPONSE,
      setBlockedCommunicationProfiles: types.SET_BLOCKED_COMMUNICATION_PROFILES,
      loadProfilesByLogin: types.LOAD_PROFILES_BY_LOGIN,
      impersonateProfile: types.IMPERSONATE_PROFILE,
      endImpersonationMode: types.END_IMPERSONATION_MODE,
      loadInternalDocument: types.ACTION_LOAD_INTERNAL_DOCUMENT,
      loadExternalDocument: types.ACTION_LOAD_EXTERNAL_DOCUMENT,
    }),
    ...mapMutations({
      resetConsent: types.MUTATE_RESET_MYPROFILE_PENDING_CONSENT,
      setImpersonationMode: types.MUTATE_SET_IMPERSONATION_MODE,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setIsMobile: types.MUTATE_IS_MOBILE,
      setIsTablet: types.MUTATE_IS_TABLET,
      setCalendarActiveInstProfileId: types.MUTATE_CALENDAR_ACTIVE_INST_PROFILE_ID,
    }),
    impersonationLogin() {
      this.setImpersonationMode(true);
      const reason = Cookie.Read('reason');
      const caseId = Cookie.Read('caseId');
      this.impersonateProfile({
        instProfileId: Cookie.Read('impersonateId'),
        reason: reason,
        caseId: caseId,
      }).then(() => {
        this.isUserLoggedIn = true;
        Cookie.Create('hasLoadedImpersonateProfile', true, { expires: 14 });
      });
    },
    previewLogin() {
      const dashboardConfiguration = JSON.parse(this.$route.query.dashboardConfiguration);
      this.getProfileContext({ parent: parentTypes.PORTAL })
        .then(() => {
          this.isUserLoggedIn = true;
          return Promise.all([
            this.calculateMenuItems(),
            this.loadPreviewModulesWidgetsConfig({
              institutionCode: dashboardConfiguration.institutionCode,
              institutionRole: dashboardConfiguration.institutionRole,
              institutionType: dashboardConfiguration.institutionType,
              previewModules: dashboardConfiguration.previewModules,
              previewWidgets: dashboardConfiguration.previewWidgets,
            }),
          ]);
        })
        .then(() => {
          this.overrideThemeInPreviewMode(dashboardConfiguration);
        });
    },
    standardLogin() {
      this.getProfileContext({ parent: parentTypes.PORTAL })
        .then(() => {
          this.isUserLoggedIn = true;
          if (Cookie.Read('isOnboardingStep') && window.location.hostname.includes('aula.dk')) {
            this.$router.push({
              name: 'stepOnboarding',
              params: { step: Cookie.Read('isOnboardingStep') },
            });
          } else {
            if (!this.$route.path.includes('onboarding')) {
              this.checkIsOnboardingNeeded().then(() => {
                if (this.onboardingStatus == onboardingStatusEnum.NEEDS_STEP_UP) {
                  this.setStepUpNotification({
                    showStepUpNotification: true,
                    showStepUpForOnboarding: true,
                    redirectedUrl: this.$options.filters.getSiteUrl(),
                  });
                } else if (this.onboardingStatus === onboardingStatusEnum.REQUIRED) {
                  Cookie.Create('isOnboardingStep', this.onboardingSteps[0].step);
                  this.$router.push({
                    name: 'stepOnboarding',
                    params: { step: this.onboardingSteps[0].step },
                  });
                }
              });
            }
          }
        })
        .then(() => {
          if (!Cookie.Read('stepupUserMismatch') && localStorage.getItem('redirectedurlafterstepup')) {
            window.location.href = localStorage.getItem('redirectedurlafterstepup');
            localStorage.removeItem('redirectedurlafterstepup');
            localStorage.removeItem('redirectedurlbeforestepup');
          }
        });
    },
    loginChoosePortalRoleOtp() {
      this.loadProfilesByLogin().then(rsp => {
        if (
          rsp != null &&
          rsp.response != null &&
          rsp.response.data != null &&
          rsp.response.data.status != null &&
          (rsp.response.data.status.code == 448 ||
            rsp.response.data.status.code == 451 ||
            rsp.response.data.status.code == 452)
        ) {
          switch (rsp.response.data.status.code) {
            case 448:
              this.$router.push({ name: 'login' });
              break;
            case 451:
              this.$router.push({
                name: 'NoAccess',
                query: { type: 'accessNotGrantedYet' },
              });
              break;
            case 452:
              this.$router.push({
                name: 'NoAccess',
                query: { type: 'deactivatedUser' },
              });
              break;
          }
        } else {
          if (this.isPreviewMode) {
            // PREVIEW
            this.previewLogin();
          } else {
            // STANDARD
            this.standardLogin();
          }
        }
      });
    },
    overrideThemeInPreviewMode(dashboardConfiguration) {
      const previewInstRole = dashboardConfiguration.institutionRole;
      let portalRole = portalRoles.EMPLOYEE;
      if (previewInstRole == institutionRole.GUARDIAN) {
        portalRole = portalRoles.GUARDIAN;
      } else if (
        previewInstRole == institutionRole.EARLY_STUDENT ||
        previewInstRole == institutionRole.MIDDLE_LATE_STUDENT ||
        previewInstRole == institutionRole.DAYCARE
      ) {
        portalRole = portalRoles.CHILD;
      }
      $('body').removeClass('theme-child theme-employee theme-guardian');
      $('body').addClass('theme-' + portalRole);
    },
    changeFavIcons(themeClass) {
      if (themeClass) {
        const favIcons = document.querySelectorAll('[data-icon="favicon"]');
        if (favIcons.length > 0) {
          let iconPath = 'static/favicons/';
          if (themeClass == 'child' || themeClass == 'guardian' || themeClass == 'otp') {
            iconPath += 'blue/';
          }
          for (const favIcon of favIcons) {
            if (favIcon.content) {
              const iconName = favIcon.content.substring(favIcon.content.lastIndexOf('/') + 1);
              favIcon.content = iconPath + iconName;
            } else {
              const iconName = favIcon.href.substring(favIcon.href.lastIndexOf('/') + 1);
              favIcon.href = iconPath + iconName;
            }
          }
        }
      }
    },
    initDefaultApiMethods() {
      this.calculateMenuItems();

      if (this.profile.role === portalRoles.GUARDIAN || this.profile.role === portalRoles.CHILD) {
        this.getProfileConsent();
      }
      this.changeFavIcons(this.themeClass);
      this.initPermissions();
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
      this.setBlockedCommunicationProfiles();
      this.setCalendarActiveInstProfileId(this.profile.id);
      this.setTheme();
    },
    setTheme() {
      $('body').removeClass('theme-child theme-employee theme-guardian early-student');
      $('body').addClass('theme-' + this.themeClass);
      if (this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT)) {
        $('body').addClass('early-student');
      }
    },
    setInitialSettings() {
      const ua = navigator.userAgent.toLowerCase();

      if (ua.indexOf('chrome') > -1) {
        document.body.classList.add('chrome');
      } else if (ua.indexOf('safari') > -1) {
        document.body.classList.add('safari');
      } else if (ua.indexOf('firefox') > -1) {
        document.body.classList.add('firefox');
      }

      if (this.isMobile) {
        document.body.classList.add('mobile');
      }

      if ('ontouchstart' in document.documentElement) {
        $(this.$el).on('focus', 'input', function () {
          document.body.classList.add('keyboard');
        });

        $(this.$el).on('blur', 'input', function () {
          document.body.classList.remove('keyboard');
        });
      }

      $(document).on({
        touchstart: function (event) {
          if (!$(event.target).closest('.el-popper,.el-input__inner')) {
            $('.el-popper').hide();
          }
        },
      });
    },
    scrollEventFn(e) {
      e.preventDefault();
    },
    checkExistConsent(consentResponse) {
      let consents = [];
      for (const consent of consentResponse) {
        consents = consents.concat(consent.consentResponses);
      }
      return consents.length > 0;
    },
    detectIsMobile() {
      this.setIsMobile();
      this.setIsTablet();
    },
    cancelStepUpUserMismatch() {
      this.deleteStepupUserMismatchCookie();
      this.$refs.stepupUserMismatchModal.hide();
    },
    retryStepUp() {
      this.deleteStepupUserMismatchCookie();
      window.location.href = '/auth/stepup.php?retry=true';
    },
    deleteStepupUserMismatchCookie() {
      Vue.nextTick(() => {
        Cookie.Erase('stepupUserMismatch', '.aula.dk');
      });
    },
    initStepupMismatchModal() {
      if (Cookie.Read('stepupUserMismatch')) {
        this.$refs.stepupUserMismatchModal.show();
      }
    },
  },
  watch: {
    isUserLoggedIn() {
      if (this.isUserLoggedIn === true) {
        redirectToCalendarFeedDeepLink();

        this.$nextTick(() => {
          this.initStepupMismatchModal();
        });
      }
    },
    profile() {
      this.initDefaultApiMethods();
    },
    errorText() {
      if (this.errorText != '' && this.$refs.errorToastr != undefined) {
        this.$refs.errorToastr.show();
      }
    },
    successText() {
      if (this.successText != '' && this.$refs.successToastr != undefined) {
        this.$refs.successToastr.show();
      }
    },
    $route(to, from) {
      if (
        from.path.includes('onboarding') &&
        !to.path.includes('onboarding') &&
        !Cookie.Read('isImpersonationMode') &&
        Cookie.Read('isOnboardingStep')
      ) {
        this.$router.push({
          name: 'stepOnboarding',
          params: { step: Cookie.Read('isOnboardingStep') },
        });
      }

      if (to.meta != null && to.meta.title) {
        document.title = to.meta.title;
      }

      if (from.path.toLowerCase().includes('chooseportalrole') && to.path.toLowerCase().includes('overblik')) {
        this.loginChoosePortalRoleOtp();
      }
    },
  },

  mounted() {
    csrfprotector_init();
    window.addEventListener('resize', this.detectIsMobile);
    // set focus on input when date/time, search and tag icons are clicked
    $('body').on('click', '.aula-search-outer .icon-Aula_search', function () {
      $(this).parents('.aula-search-outer').find('input').focus();
    });
    $('body').on('click', '.el-date-editor .el-input__icon', function () {
      $(this).parents('.el-date-editor').find('input').focus();
    });
    $('body').on(
      'click',
      '.input-group-append .icon-Aula_edit_pen, .input-group-prepend .icon-Aula_search, .input-group-prepend .icon-tag',
      function () {
        $(this).parents('.input-group').find('input').focus();
      }
    );
    if (this.$route.meta.title) {
      document.title = this.$route.meta.title;
    }
  },
  components: {
    DebugApiErrorModal,
    Unsubscribe,
    AulaModals,
    'aula-widget-mobile': Widget,
    'aula-privacy-policy': OpenPrivacyPolicy,
    'aula-login': Login,
    'aula-noaccess': NoAccess,
    'aula-stepupfailure': StepUpFailure,
    'aula-onboarding': Onboarding,
    'aula-header': Header,
    'aula-menu': Menu,
    alerts: Alerts,
    'aula-modals': Modals,
    'step-up-notification': StepUpNotification,
    'session-timeout-warning': SessionTimeOutWarning,
    'aula-choose-portal-role': ChoosePortalRole,
  },
};
</script>

<style lang="scss">
@import '../shared/assets/scss/core/variables.scss';
@import '../shared/assets/scss/core/breakpoints.scss';

.impersonation-header {
  width: 50%;
  position: fixed;
  background: $color-error-text;
  height: var(--menu-top-bar-height);
  z-index: $impersonate-header-z-index;
  top: 0;
  left: 0;
  color: $color-white;
  padding: 15px;
  text-align: center;
  font-size: 16px;

  h1 {
    font-weight: normal;
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

  button {
    float: right;
  }
}

/* Top bar of Aula locked at top of screen when keyboard opens on ios */
.safari.keyboard .aula-content {
  bottom: 270px;
}

.content-wrapper {
  transition: margin-top 350ms;
}

.main-toastr-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;

  @include breakpoint-sm-down() {
    width: auto;
    top: 20px;
    right: 20px;
    left: 20px;
  }
}
</style>
