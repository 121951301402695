<template>
  <div class="subscription-header" :class="{ 'header-media-print': stickThreadMetadata }">
    <h1 class="thread-subject">
      <template v-if="isForwardedConversation">
        <i class="icon-Aula_redirect" />
      </template>
      {{ shownSubscription.subject | decodeEntity }}
    </h1>

    <div v-if="stickThreadMetadata" class="thread-metadata-sticky" :class="{ 'text-truncate': !isMobile }">
      <div class="meta-info" :class="{ truncate: !isMobile }">
        <span class="subject" :class="{ truncate: isMobile }"
          ><template v-if="isForwardedConversation"><i class="icon-Aula_redirect" /></template
          >{{ shownSubscription.subject | decodeEntity }}</span
        >
        <span class="participants pl-1" :class="{ truncate: isMobile }">{{ topBarThreadInfo }}</span>
      </div>
      <b-btn variant="link" class="see-more" @click="scrollToTop">
        <i class="icon-Aula_elipses" :aria-label="'MESSAGE_SEE_MORE_RECIPIENTS' | fromTextKey" />
      </b-btn>
    </div>
    <div class="thread-metadata">
      {{ 'MESSAGE_CONVERSATION_HEADER_PART_1' | fromTextKey }}
      {{ shownSubscription.threadStartedDateTime | longDate(true, true) }}
      {{ 'MESSAGE_CONVERSATION_HEADER_PART_2' | fromTextKey }}
      <span class="thread-metadata-creator" v-html="getParticipantNames(shownSubscription.threadCreator)" />
      <div v-if="threadCreator" class="subject-sent-to">
        <span>{{ 'MESSAGE_SENT_TO' | fromTextKey }}: </span>
        <span class="inline-elements">
          <span class="subscriber" v-html="getParticipantNames(threadCreator)" />
        </span>
        <template v-if="showListOfParticipants">
          <i
            class="icon-Aula_up-arrow"
            tabindex="0"
            role="button"
            :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
            @click="showListOfParticipants = false"
            @keydown.enter="showListOfParticipants = false"
          />
          <div class="offset-1 col-10">
            <template v-for="(subscription, i) in sortedParticipants">
              <span :key="i" class="subscriber" v-html="getParticipantNames(subscription)" />
            </template>
          </div>
        </template>
        <template v-else>
          <span v-if="sortedParticipants.length > 0" class="and-others"
            >,
            <template v-for="(recipient, index) in sortedParticipants">
              <span v-if="index < 2" :key="index" class="subscriber" v-html="getParticipantNames(recipient)" />
            </template>
          </span>
          <span v-if="sortedParticipants.length > 2" class="and-others">
            {{ 'MESSAGE_AND' | fromTextKey }}
            {{ sortedParticipants.length - 2 }}
            {{ 'MESSAGE_OTHERS' | fromTextKey }}
            <i
              class="icon-Aula_down-arrow"
              tabindex="0"
              role="button"
              :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
              @click="showListOfParticipants = true"
              @keydown.enter="showListOfParticipants = true"
            />
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import $ from 'jquery';

export default {
  props: {
    shownSubscription: Object,
  },
  data: function () {
    return {
      messageOwnerTypes: messageOwnerTypes,
      showListOfParticipants: false,
      institutionRole: institutionRole,
      stickThreadMetadata: false,
      topBarThreadInfo: '',
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
    }),
    isForwardedConversation() {
      return this.shownSubscription && this.shownSubscription.isForwarded;
    },
    threadParticipants() {
      return this.shownSubscription.recipients.filter(r => r.leaveTime == null);
    },
    threadCreator() {
      return this.threadParticipants.length > 0 ? this.threadParticipants[0] : null;
    },
    sortedParticipants() {
      const moreParticipants = this.threadParticipants.slice(1);
      return moreParticipants.sort((a, b) => a.fullName.localeCompare(b.fullName));
    },
    commonInboxText() {
      return Vue.filter('fromTextKey')('MESSAGE_COMMON_INBOX_DESCRIPTION');
    },
  },
  mounted() {
    this.topBarThreadInfo = this.getStickyParticipantNames();
    document.querySelector('.threads-container').addEventListener('scroll', this.handleThreadScroll);
  },
  created() {
    $('body').on('click', '.childExpand', function () {
      const id = '#full-' + $(this).attr('id');
      $(this).hide();
      $(id).show();
    });
  },
  methods: {
    scrollToTop() {
      $('.threads-container').animate({ scrollTop: 0 });
    },
    handleThreadScroll() {
      const messageHeader = $('.subscription-header');
      if (!this.isMobile) {
        this.stickThreadMetadata = messageHeader.offset().top < 80;
      } else {
        this.stickThreadMetadata = messageHeader.offset().top < -40;
      }
    },
    getStickyParticipantNames() {
      let participants = '';
      participants += this.getParticipantNames(this.threadCreator, true);
      if (this.threadParticipants.length > 0) {
        for (let i = 1; i < 3; i++) {
          if (this.threadParticipants[i] != null) {
            participants += ', ' + this.getParticipantNames(this.threadParticipants[i], true);
          }
        }
        if (this.threadParticipants.length > 3) {
          participants +=
            ' + ' + (this.threadParticipants.length - 3) + ' ' + this.$options.filters.fromTextKey('MESSAGE_OTHERS');
        }
      }
      return participants;
    },
    getParticipantNames(item, isTopbar = false) {
      if (item != null) {
        let name = item.fullName;
        if (item.metadata != null) {
          const childrenName = item.metadata.split(',');
          if (childrenName.length > 3) {
            const subMedatadata = childrenName.slice(0, 3).join(',');
            if (isTopbar) {
              name += ' (' + subMedatadata + ',...)';
            } else {
              name +=
                ' (' +
                subMedatadata +
                ' <span class="childExpand" title="Se mere" id="metadata' +
                item.mailBoxOwner.id +
                '">+</span> ';
              name +=
                '<span class="childList" id="full-metadata' +
                item.mailBoxOwner.id +
                '">' +
                childrenName.slice(4).join(',') +
                '</span>)';
            }
          } else {
            name += ' (' + item.metadata + ')';
          }
        }
        return name;
      } else {
        return '';
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.header-media-print {
  display: none;
}

.subscription-header {
  padding-top: 15px;
  text-align: center;
  font-size: 13px;
  display: block;
  .theme-employee & {
    color: $color-primary-darker-employee;
  }
  .theme-guardian & {
    color: $color-primary-darker-guardian;
  }
  .theme-child & {
    color: $color-primary-darker-child;
  }
  .theme-admin & {
    color: $color-primary-darker-admin;
  }
  @include breakpoint-xs-down() {
    font-size: 14px;
  }

  .thread-metadata-sticky {
    text-align: left;
    padding-left: 10px;
    position: absolute;
    top: 15px;
    left: 610px;
    right: 100px;

    .meta-info {
      max-width: calc(100% - 80px);
      width: auto;
      display: inline-block;
      @include breakpoint-lg-down() {
        max-width: unset;
        width: 100%;
      }
      .subject {
        font-size: 14px;
        font-weight: 900;
      }
    }
    .see-more {
      position: relative;
      top: -8px;
      .icon-Aula_elipses {
        margin-left: 10px;
      }
    }

    @include breakpoint-lg-down() {
      display: block;
      background-color: $color-grey-base;
      padding: 10px 50px 10px 20px;
      width: 100%;
      top: 60px;
      z-index: 1000;
      left: 0;

      .subject {
        display: block;
      }
      .participants {
        width: 100%;
        display: block;
      }
      .see-more {
        position: absolute;
        top: 18px;
        right: 10px;
      }
    }
  }
  .thread-subject {
    font-weight: 900;
    font-size: 18px;
    line-height: 1.5;
    [class^='icon-Aula_redirect'] {
      font-size: 18px;
      position: relative;
      top: -5px;
      margin-right: 5px;
    }
    @include breakpoint-sm-up() {
      font-size: 22px;
      margin-bottom: 8px;
    }
  }

  .thread-metadata {
    line-height: 24px;
    margin: 0 20px 5px 20px;
  }

  .icon-Aula_down-arrow,
  .icon-Aula_up-arrow {
    font-size: 5px;
    cursor: pointer;
    position: relative;
    bottom: 1px;
  }

  .inline-elements {
    display: inline;
  }

  .subject-sent-to {
    display: table;
    margin: 0 auto;
  }

  .container {
    min-width: 100%;
  }

  .subscriber {
    text-align: left;
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
    /deep/ .childExpand {
      cursor: pointer;
      display: inline-block;
    }
    /deep/ .childList {
      display: none;
    }
  }

  .sent-to-text {
    padding: 0 2px 0 0;
    text-align: right;
  }

  .and-others {
    text-align: left;
    padding: 0 0 0 2px;
  }
}
</style>
