<template>
  <div id="shown-subscription-container" class="shown-subscription-container">
    <div class="compact-header">
      <strong>{{ shownSubscription.subject | decodeEntity }}</strong>
      {{ 'MESSAGE_CONVERSATION_HEADER' | fromTextKey }}
      {{ shownSubscription.threadCreator | displayProfileNameWithMetadata }}
      <i class="icon icon-Aula_elipses" @click="scrollToHead" />
    </div>
    <subscription-header :shown-subscription="shownSubscription" />
    <subscription-functionalities :shown-subscription="shownSubscription" />
    <div v-if="shownSubscription.moreMessagesExist" class="load-more">
      <div role="button" tabindex="0" @click="addMoreMessages" @keydown.enter="addMoreMessages">
        {{ 'MESSAGE_LIST_GET_OLDER' | fromTextKey }}
      </div>
    </div>
    <conversation
      :shown-subscription="shownSubscription"
      @toggleLoadMoreButton="toggleLoadMoreButton"
      @reloadSubscription="$emit('reloadSubscription')"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import SubscriptionHeader from './SubscriptionHeader.vue';
import SubscriptionFunctionalities from './SubscriptionFunctionalities.vue';
import Conversation from './Conversation.vue';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';

export default {
  components: {
    'subscription-header': SubscriptionHeader,
    'subscription-functionalities': SubscriptionFunctionalities,
    conversation: Conversation,
  },
  provide() {
    return {
      [messageProviderKeyEnum.IS_THREAD_CREATOR]: () => this.isThreadCreator,
    };
  },
  props: {
    shownSubscription: Object,
  },
  data: function () {
    return {
      showLoadMoreButton: Boolean,
    };
  },
  computed: {
    ...mapGetters({
      moreMessagesExist: types.MESSAGES_GET_MORE_MESSAGES_EXITS,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      institutions: types.GET_INSTITUTIONS,
    }),
    isThreadCreator() {
      const threadCreatorMailBox = this.shownSubscription.threadCreator.mailBoxOwner;

      if (threadCreatorMailBox.mailBoxOwnerType !== this.chosenFolderAndMailOwner.mailOwnerType) {
        return false;
      }

      if (threadCreatorMailBox.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
        const institutionProfileIds = this.institutions.map(inst => inst.institutionProfileId);
        return institutionProfileIds.includes(threadCreatorMailBox.id);
      }

      return threadCreatorMailBox.id === this.chosenFolderAndMailOwner.mailOwnerId;
    },
  },
  methods: {
    ...mapActions({
      loadMoreMessages: types.LOAD_MORE_MESSAGES,
    }),
    addMoreMessages() {
      this.loadMoreMessages({
        scrollElement: this.$el.closest('.conversation-holder'),
      });
    },
    toggleLoadMoreButton(shown) {
      this.showLoadMoreButton = shown;
    },
    scrollToHead() {
      Vue.nextTick(() => {
        $('.threads-container').animate({ scrollTop: 0 }, 'fast');
      });
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.compact-header {
  display: none;
  font-size: 14px;
  position: absolute;
  left: 80px;
  top: -35px;
  @include breakpoint-xl {
    left: 100px;
    top: -35px;
  }
  strong {
    margin-right: 5px;
  }
  .icon {
    font-size: 6px;
    position: relative;
    left: 7px;
    top: -1px;
    cursor: pointer;
  }
}

.shown-subscription-container {
  width: 100%;
  height: 100%;
}

.load-more {
  text-align: center;

  div {
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    vertical-align: middle;
    font-size: 13px;
    padding: 15px;
    background-color: $color-grey-light;
    border-radius: 5px;
    box-shadow: 0 2px 4px 0 rgba(193, 192, 192, 0.5);
    z-index: 1;
    left: 58%;
    cursor: pointer;
  }
}
</style>
