<template>
  <div class="onboarding-step2">
    <div class="step2-content scrollbar">
      <b-row v-for="(profile, profileIndex) in institutionProfiles" :key="'profile' + profile.profileId">
        <b-col
          v-if="profile.institutionProfiles.filter(p => p.isEdit == true).length > 0"
          cols="12"
          class="portal-roles"
        >
          <h1>
            <template v-if="profile.portalRole == portalRoles.GUARDIAN">
              {{ 'PARENT' | fromTextKey }}
            </template>
            <template v-if="profile.portalRole == portalRoles.EMPLOYEE">
              {{ 'ONBOARDING_EMPLOYEE_PROFILES' | fromTextKey }}
            </template>
          </h1>
        </b-col>
        <b-col v-if="canChangePrimaryInstitution" cols="12" lg="8">
          <ContactInformationPrimaryInstitution
            v-model="primaryInstitutionProfileId"
            class="primary-institution"
            :institutions="institutions"
          >
            <template #label>
              <label class="mb-0">{{ 'ONBOARDING_CHOOSE_PRIMARY_INSTITUTION' | fromTextKey }} {{ roleLabel }}</label>
            </template>
          </ContactInformationPrimaryInstitution>
        </b-col>
        <div class="w-100" />
        <template v-for="(institutionProfile, instProfileIndex) in profile.institutionProfiles">
          <b-col
            v-if="
              profile.institutionProfiles != null && profile.institutionProfiles.length > 0 && institutionProfile.isEdit
            "
            :key="profile.profileId + institutionProfile.id"
            cols="12"
            md="12"
            lg="6"
            class="margin-bottom-40"
          >
            <onboarding-update-institution-profile
              :is-child="profile.portalRole === portalRoles.CHILD"
              :show-inst-name="profile.institutionProfiles.length > 1"
              :inst-profile="institutionProfile"
              :profile-index="profileIndex"
              :institution-profile-index="instProfileIndex"
              @disableSubmitButton="disableSubmitButton"
            />
          </b-col>
        </template>
        <b-col v-if="profile.portalRole === portalRoles.GUARDIAN" cols="12" />
        <template v-for="(child, childIndex) in profile.children">
          <b-col
            v-if="
              profile.portalRole == portalRoles.GUARDIAN &&
              profile.children != null &&
              profile.children.length > 0 &&
              child.institutionProfile.newInstitutionProfile
            "
            :key="'child' + childIndex"
            cols="12"
            md="12"
            lg="6"
            class="margin-bottom-40"
          >
            <onboarding-update-institution-profile
              :is-child="true"
              :inst-profile="child.institutionProfile"
              :profile-index="profileIndex"
              :child-index="childIndex"
              @disableSubmitButton="disableSubmitButton"
            />
          </b-col>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapGetters } from 'vuex';
import { portalRoles } from '../../../shared/enums/portalRoles';
import OnboardingUpdateInstProfile from './OnboardingUpdateInstProfile.vue';
import ContactInformationPrimaryInstitution from '../contacts/ContactInformationPrimaryInstitution.vue';

export default {
  data: function () {
    return {
      portalRoles: portalRoles,
      primaryInstitutionProfileId: null,
      initPrimaryInstitutionProfileId: null,
      holderPrimaryInstitutionProfileId: null,
      isChangedPrimaryInstProfile: false,
      disableButton: {},
      aulaContactInfo: null,
      institutionProfiles: [],
    };
  },
  computed: {
    ...mapGetters({
      userProfiles: types.GET_PROFILES_BY_LOGIN,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
    }),
    roleLabel() {
      if (this.profile.role === portalRoles.EMPLOYEE) {
        return this.$options.filters.fromTextKey('INSTITUTION_ROLE_EMPLOYEE');
      }
      if (this.profile.role === portalRoles.GUARDIAN) {
        return this.$options.filters.fromTextKey('INSTITUTION_ROLE_GUARDIAN');
      }
      return '';
    },
    canChangePrimaryInstitution() {
      const isRoleAllowed = [portalRoles.EMPLOYEE, portalRoles.GUARDIAN].includes(this.profile.role);
      return isRoleAllowed && this.institutions.length > 1;
    },
  },
  mounted() {
    this.institutionProfiles = this.getInstitutionProfiles();
  },
  methods: {
    getInstitutionProfiles() {
      const userProfiles = this.userProfiles;
      const activePortalRole = this.profile.role;
      const profile = userProfiles.find(p => p.portalRole === activePortalRole);
      if (!profile) {
        return [];
      }

      if (profile.institutionProfiles.length > 0) {
        for (const index in profile.institutionProfiles) {
          this.disableButton[profile.institutionProfiles[index].id] = false;
          if (profile.institutionProfiles[index].newInstitutionProfile) {
            profile.institutionProfiles[index].isEdit = true;
          }
          if (profile.institutionProfiles[index].isPrimary) {
            if (this.primaryInstitutionProfileId == null) {
              this.primaryInstitutionProfileId = profile.institutionProfiles[index].id;
            }
          } else {
            this.holderPrimaryInstitutionProfileId = profile.institutionProfiles[index].id;
          }
          if (profile.portalRole == portalRoles.GUARDIAN && !profile.institutionProfiles[index].isPrimary) {
            profile.institutionProfiles[index].isEdit = false;
          }
        }
      }
      if (profile.children != null && profile.children.length > 0) {
        for (const child of profile.children) {
          this.disableButton[child.institutionProfile.id] = false;
        }
      }

      if (this.primaryInstitutionProfileId != null) {
        if (this.initPrimaryInstitutionProfileId == null) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.initPrimaryInstitutionProfileId = this.primaryInstitutionProfileId;
        }
      } else if (this.holderPrimaryInstitutionProfileId != null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.primaryInstitutionProfileId = this.holderPrimaryInstitutionProfileId;
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.initPrimaryInstitutionProfileId = this.primaryInstitutionProfileId;
      }
      return [profile];
    },
    getChildAulaContactInfo(institutionProfile) {
      if (this.aulaContactInfo != null) {
        return this.aulaContactInfo.relations.find(rel => rel.id == institutionProfile.id);
      }
      return null;
    },
    updateContactInfo() {
      const updateProfiles = [];
      for (const profile of this.userProfiles) {
        if (profile.portalRole !== portalRoles.OTP) {
          if (profile.institutionProfiles.length > 0) {
            for (const instProfile of profile.institutionProfiles) {
              if (instProfile.isEdit) {
                updateProfiles.push({
                  profileId: profile.profileId,
                  institutionProfileId: instProfile.id,
                  homePhoneNumber: instProfile.homePhoneNumber,
                  mobilePhoneNumber: instProfile.mobilePhoneNumber,
                  workPhoneNumber: instProfile.workPhoneNumber,
                  email: instProfile.email,
                });
              }
            }
          }
          if (profile.children != null && profile.children.length > 0) {
            for (const child of profile.children) {
              updateProfiles.push({
                profileId: child.institutionProfile.profileId,
                institutionProfileId: child.institutionProfile.id,
                homePhoneNumber: child.institutionProfile.homePhoneNumber,
                mobilePhoneNumber: child.institutionProfile.mobilePhoneNumber,
                email: child.institutionProfile.email,
              });
            }
          }
        }
      }
      let primaryInstitutionProfileId = null;
      if ([portalRoles.EMPLOYEE, portalRoles.GUARDIAN].includes(this.profile.role)) {
        primaryInstitutionProfileId = this.primaryInstitutionProfileId;
      }
      const payload = {
        institutionProfilesMasterData: updateProfiles,
        primaryInstitutionProfileId,
        isChangedPrimaryInstProfile: this.initPrimaryInstitutionProfileId != this.primaryInstitutionProfileId,
      };
      return payload;
    },
    disableSubmitButton(val, institutionProfileId) {
      this.disableButton[institutionProfileId] = val;
      let disabledSubmit = false;
      for (const key of Object.keys(this.disableButton)) {
        if (this.disableButton[key]) {
          disabledSubmit = true;
        }
      }
      const stepIndex = this.$parent.stepStatus.findIndex(s => s.step == 'update');
      this.$parent.stepStatus[stepIndex].disabledSubmit = disabledSubmit;
    },
  },
  components: {
    ContactInformationPrimaryInstitution,
    'onboarding-update-institution-profile': OnboardingUpdateInstProfile,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/core/animations.scss';
@import '../../../shared/assets/scss/core/icons.scss';
@import '../../../shared/assets/scss/components/login/onboarding.scss';

.primary-institution {
  --border-color: var(--color-grey-darkest);
}
</style>
