<template>
  <nav id="subscription-functionalities-id" class="subscription-functionalities mx-auto">
    <b-nav justified>
      <b-nav-item v-if="!isMobile || isReminderThread" @click="markThreadToggle(shownSubscription)">
        <i :class="shownSubscription.marked ? 'icon-Aula_star_solid colored' : 'icon-Aula_star'" />
        <span v-if="!shownSubscription.marked" class="thread-functions mark-important-text">{{
          'MESSAGE_MARK' | fromTextKey
        }}</span>
        <span v-else class="mark-important-text">{{ 'MESSAGE_REMOVE_MARK' | fromTextKey }}</span>
      </b-nav-item>
      <b-nav-item v-if="selectedThread" @click="onChangeSubscriptionStatus()">
        <i :class="selectedThread.read ? 'icon-Aula_envelope_closed' : 'icon-Aula_envelope_open'" />
        <span v-if="selectedThread.read" class="mark-read-unread-text">{{
          isMobile ? 'MESSAGE_MARK_AS_UNREAD_SHORT' : 'MESSAGE_MARK_AS_UNREAD' | fromTextKey
        }}</span>
        <span v-else class="mark-read-unread-text">{{
          isMobile ? 'MESSAGE_MARK_AS_READ_SHORT' : 'MESSAGE_MARK_AS_READ' | fromTextKey
        }}</span>
      </b-nav-item>
      <b-nav-item v-if="canMoveToFolder && (!isMobile || isReminderThread)" @click="changeMoveToFolderVisibility(true)">
        <i class="icon-Aula_folder" />
        <span class="thread-functions">{{ 'MESSAGE_MOVE_FOLDER' | fromTextKey }}</span>
      </b-nav-item>
      <b-nav-item @click="$refs.deleteModal.show()">
        <i class="icon-Aula_bin" />
        <span class="thread-functions">{{ 'MESSAGE_DELETE_THREAD' | fromTextKey }}</span>
      </b-nav-item>
      <b-nav-item-dropdown v-if="canHandleMoreActions" class="dropdown-select" right no-caret>
        <template slot="button-content">
          <i class="icon-Aula_elipses" />
          <span>{{ 'MESSAGE_DROPDOWN_MORE' | fromTextKey }}</span>
        </template>
        <b-dropdown-item v-if="isMobile && canMoveToFolder" @click="changeMoveToFolderVisibility(true)">
          <i class="icon-Aula_folder" />
          <span class="mark-important-text">{{ 'MESSAGE_MOVE_FOLDER' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="isMobile" @click="markThreadToggle(shownSubscription)">
          <i :class="shownSubscription.marked ? 'icon-Aula_star_solid colored' : 'icon-Aula_star'" />
          <span class="mark-important-text">{{
            shownSubscription.marked ? 'MESSAGE_REMOVE_MARK_LONG' : 'MESSAGE_MARK_LONG' | fromTextKey
          }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          :disabled="Boolean(shownSubscription.leaveTime) || !hasPermissionToWriteMessage"
          @click="handleSharingActions(false)"
        >
          <i class="icon-Aula_plus" />
          <span>{{ 'MESSAGE_DROPDOWN_ADD_RECIPIENTS' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item :disabled="!canForwardThread" @click="handleSharingActions(true)">
          <i class="icon-Aula_redirect space" />
          <span>{{ 'MESSAGE_FORWARDING' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="shownSubscription.muted"
          :disabled="Boolean(shownSubscription.leaveTime)"
          :title="'MESSAGE_DROPDOWN_UNMUTE_HELP' | fromTextKey"
          @click="setMutedStatus(shownSubscription)"
        >
          <i class="icon-Bell_2 colored" />
          <span>{{ 'MESSAGE_DROPDOWN_UNMUTE' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-else
          :disabled="Boolean(shownSubscription.leaveTime)"
          :title="'MESSAGE_DROPDOWN_MUTE_HELP' | fromTextKey"
          @click="setMutedStatus(shownSubscription)"
        >
          <i class="icon-Bell" />
          <span>{{ 'MESSAGE_DROPDOWN_MUTE' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="showLeaveButton"
          :title="'MESSAGE_DROPDOWN_LEAVE_HELP' | fromTextKey"
          @click="$refs.leaveThreadModal.show()"
        >
          <i class="icon-Aula_exclamation_sign" />
          <span>{{ 'MESSAGE_DROPDOWN_LEAVE' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="!shownSubscription.sensitive"
          :disabled="!hasPermissionToWriteMessage"
          :title="'MESSAGE_DROPDOWN_SENSITIVE_HELP' | fromTextKey"
          @click="$refs.markAsSensitiveModal.show()"
        >
          <i class="icon-Aula_lock" />
          <span>{{ 'MESSAGE_DROPDOWN_SENSITIVE' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="shownSubscription.sensitive && profile.role === portalRoles.EMPLOYEE"
          @click="$refs.unmarkAsSensitiveModal.show()"
        >
          <i class="icon-Aula_lock selected" />
          <span>{{ 'MESSAGE_DROPDOWN_UNSENSITIVE' | fromTextKey }}</span>
        </b-dropdown-item>
        <b-dropdown-item v-if="canAttachToDocument" @click="selectMessages(true)">
          <i class="icon-Aula_paper" />
          <span>{{ 'MESSAGE_DROPDOWN_CONNECT' | fromTextKey }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-nav>
    <move-to-folder-modal
      v-if="showMoveToFolder"
      :chosen-folder-and-mail-owner="chosenFolderAndMailOwner"
      :thread-ids="[shownSubscription.id]"
      :folders="!!chosenFolderAndMailOwner.mailOwnerId ? commonInboxFolders : folders"
      @hide="changeMoveToFolderVisibility"
    />
    <aula-modal
      ref="leaveThreadModal"
      header-text="MESSAGE_LEAVE_MODAL_HEADER"
      ok-text="MESSAGE_LEAVE_MODAL_OK"
      @cancelClicked="$refs.leaveThreadModal.hide()"
      @okClicked="leaveThisThread(shownSubscription)"
    >
      {{ 'MESSAGE_LEAVE_MODAL_BODY_1' | fromTextKey }}<br />{{ 'MESSAGE_LEAVE_MODAL_BODY_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      header-text="MESSAGE_CONFIRM_DELETE"
      ok-text="MESSAGE_DELETE_OK"
      data-size="small"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteThisThread"
    >
      {{ 'MESSAGE_DELETE_THREAD_INFO_1' | fromTextKey }}<br />
      {{ deletedThreadWarningMessage }} <br />
      {{ 'MESSAGE_DELETE_THREAD_INFO_3' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="addRecipientsModalVisible"
      css-class="addRecipientsModal"
      header-text="MESSAGE_ADD_RECIPIENTS_HEADER"
      :disable-submit="showAlertNoParticipants || iniviteExistingRecipients.length > 0"
      :is-loading="isLoading"
      @hidden="hideAddRecipientsModal"
      @cancelClicked="hideAddRecipientsModal"
      @okClicked="addRecipients"
    >
      <aula-search-recipients
        :include-otp-from-guardian-in-sub-group="true"
        :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
        :doc-types-api="[docTypes.GROUP, docTypes.PROFILE, docTypes.COMMON_INBOX]"
        :reset-input="resetAddRecipientsInput"
        :existing-participants="existingParticipants"
        :enable-relatives-for-students="true"
        :add-existing-participants="false"
        :allow-create="false"
        :invite-members-when-selecting-group="true"
        :invite-members-when-selecting-group-portal-role="true"
        :invite-group-when-adding-members="true"
        :only-unique-profiles="true"
        :close-dropdown-after-select="false"
        :show-search-icon="true"
        :from-module="moduleTypes.MESSAGES"
        :class="showAlertNoParticipants || iniviteExistingRecipients.length > 0 ? 'red-border' : ''"
        :include-self="chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX"
        :institution-code="
          institutions.find(inst => inst.institutionProfileId == shownSubscription.mailBoxOwner.id) != null
            ? institutions.find(inst => inst.institutionProfileId == shownSubscription.mailBoxOwner.id).institutionCode
            : null
        "
        :scope-employees-to-institution="scopeEmployeesToInstitution"
        :extra-a-p-i-params="extraSearchParam"
        :is-multiple-institution-profile-allowed="false"
        :select-all="false"
        include-choosen-in-search
        class="addRecipients"
        @emitSelected="setAddMessageRecipient"
      />
      <b-alert variant="danger" :show="showAlertNoParticipants || iniviteExistingRecipients.length > 0">
        <template v-if="iniviteExistingRecipients.length > 0">
          {{ 'MESSAGE_ADD_RECIPIENTS_EXISTED_RECIPIENTS' | fromTextKey }}
          <b>{{ iniviteExistingRecipients.join(', ') }}</b>
        </template>
        <template v-else>
          {{ 'MESSAGE_ADD_RECIPIENTS_NO_RECIPIENTS' | fromTextKey }}
        </template>
      </b-alert>
    </aula-modal>
    <aula-modal
      ref="unmarkAsSensitiveModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      :disable-close="true"
      @hidden="hideAddRecipientsModal"
      @closeClicked="cancelMarkSensitive"
      @cancelClicked="cancelMarkSensitive"
      @okClicked="unmarkAsSensitive(shownSubscription.id)"
    >
      {{ 'UNMARK_AS_SENSITIVE_MESSAGE' | fromTextKey }}<br />
      {{ 'UNMARK_AS_SENSITIVE_MESSAGE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="markAsSensitiveModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      @hidden="hideAddRecipientsModal"
      @closeClicked="cancelUnmarkSensitive"
      @cancelClicked="cancelUnmarkSensitive"
      @okClicked="markAsSensitive(shownSubscription.id)"
    >
      {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE' | fromTextKey }}<br />
      {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="modalInfoBoxForBlockedCommunication"
      header-text="MESSAGE_BLOCK_COMMUNICATION_TITLE"
      :show-cancel="false"
      @okClicked="$refs.modalInfoBoxForBlockedCommunication.hide()"
    >
      {{ 'MESSAGE_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationToAllProfiles"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.blockedCommunicationToAllProfiles.hide()"
      @okClicked="resetBlockedCommunicationModal"
    >
      {{ 'MESSAGE_MODAL_WARNING' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationWarning"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="POST_BUTTON_OK"
      cancel-text="POST_BUTTON_FORTRYD"
      @okClicked="$refs.blockedCommunicationWarning.hide()"
      @cancelClicked="cancelBlockedCommunication"
    >
      {{ 'MESSAGE_MODAL_WARNING_BEGINNING' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (profile.role || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_MIDDLE' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (blockedCommunicationToProfile.profileType || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_END' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="allRecipientsHaveBlockCommunicationChannel"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.allRecipientsHaveBlockCommunicationChannel.hide()"
    >
      {{ 'WARNING_ALL_RECIPIENTS_HAVE_BLOCK_COMMUNICATION_CHANNEL' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.theCommunicationChannelIsBlocked.hide()"
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.theCommunicationChannelIsBlocked.hide()"
      @okClicked="
        $refs.theCommunicationChannelIsBlocked.hide();
        removeBlocked();
      "
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS_REMOVE' | fromTextKey }}
      <div class="mt-2">
        {{
          blockedProfiles.map(u => u.institutionProfile.name).join(', ') +
          (blockedProfiles.length > 0 && blockedOtps.length > 0 ? ', ' : '') +
          (blockedOtps.length > 0 ? blockedOtps.map(u => u.otpInbox.displayName).join(', ') : '')
        }}
      </div>
    </aula-modal>
    <aula-modal
      ref="forwardWarningModal"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.forwardWarningModal.hide()"
      @okClicked="
        $refs.forwardWarningModal.hide();
        forwardThread(shownSubscription.id);
      "
    >
      {{ 'WARNING_FORWARD_MESSAGE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="newRecipientsWarningModal"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.newRecipientsWarningModal.hide()"
      @okClicked="
        $refs.newRecipientsWarningModal.hide();
        showAddRecipientsModal();
      "
    >
      {{ 'WARNING_NEW_RECIPIENTS_ADDED' | fromTextKey }}
    </aula-modal>
  </nav>
</template>

<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import MessageMoveToFolderModal from './MessageMoveToFolderModal.vue';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { docTypes } from '../../../shared/enums/docTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { sensitivityLevel } from '../../../shared/enums/sensitivityLevel';
import { portalRoles } from '../../../shared/enums/portalRoles';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import { subscriptionStatus } from '../../../shared/enums/subscriptionStatus';
import { communicationChannelTypes } from '../../../shared/enums/communicationChannelTypes';
import $ from 'jquery';
import { memberTypes } from '../../../shared/enums/memberTypes';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageUtil } from '../../../shared/utils/messageUtil';

export default {
  mixins: [requestCancelTokenMixin],
  inject: {
    getDeletedFolderId: messageProviderKeyEnum.GET_DELETED_FOLDER_ID,
    getCanMoveToDeletedMessageFolder: messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER,
    getIsReminderThread: messageProviderKeyEnum.GET_IS_REMINDER_THREAD,
  },
  props: {
    shownSubscription: Object,
  },
  data: function () {
    return {
      blockedProfiles: [],
      blockedGroups: [],
      blockedOtps: [],
      portalRoles: portalRoles,
      sensitivityLevel: sensitivityLevel,
      parentTypes: parentTypes,
      messageOwnerTypes: messageOwnerTypes,
      moduleTypes: moduleTypes,
      docTypes: docTypes,
      permissionEnum: permissionEnum,
      showMoveToFolder: false,
      existingRecipients: [],
      iniviteExistingRecipients: [],
      recipients: [],
      recipientsFromGroups: [],
      resetAddRecipientsInput: false,
      blockedCommunicationToProfile: {
        profileType: '',
      },
      blockedCommunicationToAllProfiles: false,
      showAlertNoParticipants: false,
      isLoading: false,
      loadMessagesCancelTokenSource: null,
      selectedThread: null,
    };
  },
  computed: {
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      selectedSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
      chosenSubscriptions: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      commonInboxes: types.MESSAGES_GET_ALL_COMMON_INBOXES,
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
      folders: types.MESSAGES_GET_FOLDERS,
      hasPermission: types.HAS_PERMISSION,
      moreMessagesExist: types.MESSAGES_GET_MORE_MESSAGES_EXITS,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      blockedCommunicationProfiles: types.GET_BLOCKED_COMMUNICATION_PROFILES,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
      subscriptionsAndBundleThreads: types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
    }),
    isReminderThread() {
      return this.getIsReminderThread();
    },
    canHandleMoreActions() {
      return !this.isReminderThread;
    },
    scopeEmployeesToInstitution() {
      return this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX;
    },
    canMoveToDeletedMessageFolder() {
      return this.getCanMoveToDeletedMessageFolder();
    },
    extraSearchParam() {
      const selectedInbox = {
        value: this.shownSubscription.mailBoxOwner.id,
        mailBoxOwnerType: this.shownSubscription.mailBoxOwner.mailBoxOwnerType,
      };
      const groupSearchScope = messageUtil.getGroupSearchScope(selectedInbox, this.commonInboxes);
      return {
        instCode: this.shownSubscription.institutionCode,
        groupSearchScope,
        mailBoxOwnerId: selectedInbox.value,
        mailBoxOwnerType: selectedInbox.mailBoxOwnerType,
      };
    },
    deletedFolderId() {
      return this.getDeletedFolderId();
    },
    hasPermissionToWriteMessage() {
      return this.hasPermission(permissionEnum.WRITE_MESSAGE);
    },
    canForwardThread() {
      return this.hasPermissionToWriteMessage === true && this.shownSubscription.isArchived !== true;
    },
    canAttachToDocument() {
      return this.hasPermission(this.permissionEnum.HANDLE_SECURE_FILES);
    },
    showLeaveButton() {
      let isShown = true;
      if (
        this.chosenFolderAndMailOwner &&
        this.chosenFolderAndMailOwner.mailOwnerId &&
        this.chosenFolderAndMailOwner.mailOwnerType !== messageOwnerTypes.OTP_INBOX
      ) {
        isShown = false;
      }
      if (this.shownSubscription.leaveTime) {
        isShown = false;
      }
      return isShown;
    },
    canMoveToFolder() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    deletedThreadWarningMessage() {
      let message = this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2');

      if (this.chosenFolderAndMailOwner?.mailOwnerId) {
        message = this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2_COMMON');
      }
      if (this.canMoveToDeletedMessageFolder) {
        message += ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_FROM_NORMAL_FOLDER')}`;
      }
      return message;
    },
    existingParticipants() {
      const existingParticipants = [];
      for (const recipient of this.selectedSubscription.recipients) {
        if (recipient.leaveTime == null) {
          const existingRecipient = {
            id: recipient.mailBoxOwner.id,
            label: recipient.fullName,
            name: recipient.fullName,
            type: 'profile',
            value: recipient.mailBoxOwner.id,
            profileId: recipient.mailBoxOwner.profileId,
          };
          existingParticipants.push(existingRecipient);
        }
      }
      return existingParticipants;
    },
  },
  watch: {
    'selectedSubscription.subscriptionId'(newVal) {
      if (!newVal) {
        this.resetSubscription();
      }
    },
  },
  methods: {
    ...mapActions({
      muteSubscription: types.MESSAGES_MUTE,
      unmuteSubscription: types.MESSAGES_UNMUTE,
      leaveThreads: types.MESSAGES_LEAVE_THREADS,
      deleteSubscription: types.DELETE_SUBSCRIPTIONS,
      selectSubscription: types.SELECT_SUBSCRIPTION,
      moveThreadToFolder: types.MOVE_THREADS_TO_FOLDER,
      loadMoreMessages: types.LOAD_MORE_MESSAGES,
      loadMessages: types.SELECT_SUBSCRIPTION,
      switchMarkMessages: types.SWITCH_MARK_SUBSCRIPTIONS,
      addRecipientsToThread: types.ADD_RECIPIENTS_TO_THREAD,
      resetSelectedSubscription: types.RESET_SELECT_SUBSCRIPTION,
      resolveBlockedUsers: types.ACTION_GET_BLOCKED_COMMUNICATION,
      adjustSensitivity: types.ACTIONS_ADJUST_SENSITIVITY,
      setBlockedCommunicationProfiles: types.SET_BLOCKED_COMMUNICATION_PROFILES,
      updateSubscriptionStatus: types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setMessagesInSelectMode: types.MUTATE_MESSAGES_IN_SELECT_MODE,
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
      mutateDeleteSubscription: types.MUTATE_DELETE_SUBSCRIPTIONS,
    }),
    handleSharingActions(isForward) {
      if (this.shownSubscription.hasSecureDocuments) {
        isForward ? this.$refs.forwardWarningModal.show() : this.$refs.newRecipientsWarningModal.show();
      } else {
        isForward ? this.forwardThread(this.shownSubscription.id) : this.showAddRecipientsModal();
      }
    },
    setMutedStatus(shownSubscription) {
      const payload = {
        subscriptionId: shownSubscription.id,
        commonInboxId:
          shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
            ? shownSubscription.mailBoxOwner.id
            : null,
        otpInboxId:
          shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
            ? shownSubscription.mailBoxOwner.id
            : null,
      };
      if (shownSubscription.muted) {
        this.unmuteSubscription(payload);
      } else {
        this.muteSubscription(payload);
      }
    },
    selectMessages(refreshMessages) {
      if (!this.isSteppedUp) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
          parentType: parentTypes.MESSAGES,
        });
      } else {
        if (refreshMessages) {
          this.cancelPreviousLoadMessagesRequest();
          this.generateLoadMessagesCancelTokenSource();
          this.loadMessages({
            subscriptionId: this.shownSubscription.id,
            mailOwnerId:
              this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX
                ? this.chosenFolderAndMailOwner.mailOwnerId
                : null,
            otpInboxId:
              this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
                ? this.chosenFolderAndMailOwner.mailOwnerId
                : null,
            cancelToken: this.loadMessagesCancelTokenSource.token,
          }).then(() => {
            this.resetLoadMessagesCancelTokenSource();
            if (this.moreMessagesExist) {
              this.selectMessages(false);
            } else {
              this.setMessagesInSelectMode(true);
            }
          });
        } else {
          this.loadMoreMessages({
            scrollElement: this.$el.closest('.subscription-functionalities'),
          }).then(() => {
            if (this.moreMessagesExist) {
              this.selectMessages(false);
            } else {
              this.setMessagesInSelectMode(true);
            }
          });
        }
      }
    },
    async markAsSensitive(subscriptionId) {
      this.$refs.markAsSensitiveModal.hide();
      await this.adjustSensitivity({
        subscriptionId,
        sensitivityLevel: sensitivityLevel.HIGH,
      });
      if (!this.isSteppedUp) {
        this.$router.push({ name: 'messages' });
      }
    },
    unmarkAsSensitive(subscriptionId) {
      this.$refs.unmarkAsSensitiveModal.hide();
      this.adjustSensitivity({
        subscriptionId,
        sensitivityLevel: sensitivityLevel.MEDIUM,
      });
    },
    cancelUnmarkSensitive() {
      this.markedSensitive = false;
      this.$refs.markAsSensitiveModal.hide();
    },
    cancelMarkSensitive() {
      this.markedSensitive = true;
      this.$refs.unmarkAsSensitiveModal.hide();
    },
    async leaveThisThread(thread) {
      this.$refs.leaveThreadModal.hide();
      await this.leaveThreads({
        subscriptionIds: [thread.subscriptionId],
      });
      this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_LEAVE_THREAD');
      await this.selectSubscription({
        subscriptionId: thread.id,
        subscriptionLeft: true,
        otpInboxId:
          this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
            ? this.chosenFolderAndMailOwner.mailOwnerId
            : null,
      });
    },
    async deleteThisThread() {
      this.$refs.deleteModal.hide();
      if (this.canMoveToDeletedMessageFolder) {
        await this.leaveThreads({
          subscriptionIds: [this.shownSubscription.subscriptionId],
        });
        await this.moveThreadToFolder({
          subscriptionIds: [this.shownSubscription.subscriptionId],
          folderId: this.deletedFolderId,
        });
        this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER');
        this.mutateDeleteSubscription({
          threadIds: [this.shownSubscription.id],
          id: this.profile.id,
        });
      } else {
        this.deleteSubscription({
          threadIds: [this.shownSubscription.id],
        });
      }
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
      if (this.$route.params.id === this.shownSubscription.id) {
        this.resetSubscription();
      }
    },
    changeMoveToFolderVisibility(value) {
      this.showMoveToFolder = value;
    },
    removeBlocked() {
      this.recipientsFromGroups = this.filterInviteeGroups(this.recipientsFromGroups, this.blockedGroups);

      const nonBlockedProfiles = this.recipients.filter(
        profile =>
          profile.otpInboxId == null &&
          !this.blockedProfiles.some(
            user => user.portalRole !== portalRoles.OTP && profile.id === user.institutionProfile.id
          )
      );
      let nonBlockedOtps = [];
      if (this.blockedOtps.length > 0) {
        nonBlockedOtps = this.recipients.filter(
          profile => profile.otpInboxId != null && !this.blockedOtps.some(otp => profile.otpInboxId === otp.otpInbox.id)
        );
      }
      this.recipients = [...nonBlockedProfiles, ...nonBlockedOtps];

      this.resetAddRecipientsInput = true;
      this.$nextTick(() => {
        this.resetAddRecipientsInput = false;
      });
      if (this.recipients.length !== 0) {
        this.addRecipients(true);
      }
    },
    markThreadToggle(subscription) {
      this.switchMarkMessages({
        threadIds: [subscription.id],
        isMarked: !subscription.marked,
      });
    },
    async onChangeSubscriptionStatus() {
      if (this.selectedThread) {
        await this.updateSubscriptionStatus({
          subscriptionIds: [this.selectedThread.subscriptionId],
          status: this.selectedThread.read ? subscriptionStatus.UNREAD : subscriptionStatus.READ,
        });
      }

      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    isBlockedCommunicationToProfile(item) {
      let result = false;
      const isSameInstitution =
        item.institutionCode ===
        (this.blockedCommunicationProfiles.institutionCode
          ? this.blockedCommunicationProfiles.institutionCode
          : this.profile.institutionCode);
      if (isSameInstitution) {
        if (this.blockedCommunicationProfiles[item.portalRole] !== '') {
          result = true;
        }
      } else if (this.blockedCommunicationProfiles[item.portalRole] === communicationChannelTypes.MUNICIPAL) {
        result = true;
      }
      return result;
    },
    cancelBlockedCommunication() {
      this.$refs.blockedCommunicationWarning.hide();
      this.$refs.addRecipientsModalVisible.hide();
    },
    pushRecipient(recipient) {
      if (recipient.type === memberTypes.GROUP) {
        return;
      }

      if (recipient.otpInboxId != null) {
        recipient.mailBoxOwnerType = messageOwnerTypes.OTP_INBOX;
      } else if (recipient.type === docTypes.PROFILE.toLowerCase()) {
        recipient.mailBoxOwnerType = messageOwnerTypes.INSTITUTION_PROFILE;
      } else if (recipient.type === docTypes.COMMON_INBOX.toLowerCase()) {
        recipient.mailBoxOwnerType = messageOwnerTypes.COMMON_INBOX;
      }

      if (this.hasExistingRecipients(recipient) && !recipient.fromGroup) {
        this.iniviteExistingRecipients.push(recipient.label);
      } else {
        this.recipients.push(recipient);
      }
    },
    setAddMessageRecipient(users) {
      this.showAlertNoParticipants = false;
      this.recipients = [];
      this.iniviteExistingRecipients = [];
      this.recipientsFromGroups = [];
      for (const recipient of users) {
        this.pushAndMergeSubGroupsIntoOneGroup(recipient);
        this.pushRecipient(recipient);
      }
    },
    hasExistingRecipients(recipient) {
      let hasRecipientBeenAdded = false;
      const recipientPortalRole = recipient.portalRole;
      const recipientMailBoxOwnerType = recipient.role || recipient.mailBoxOwnerType;
      if ([portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE].includes(recipientPortalRole) === true) {
        hasRecipientBeenAdded = this.hasProfileBeenAddedAsRecipient(recipient);
      } else if (recipientMailBoxOwnerType === messageOwnerTypes.OTP_INBOX || recipient.otpInboxId != null) {
        hasRecipientBeenAdded = this.hasOtpInboxBeenAddedAsRecipient(recipient);
      } else if (recipientMailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
        hasRecipientBeenAdded = this.hasCommonInboxBeenAddedAsRecipient(recipient);
      }

      return hasRecipientBeenAdded === true;
    },
    hasProfileBeenAddedAsRecipient(recipient) {
      if (recipient.profileId == null) {
        return false;
      }
      const messageOwnerTypesToSkip = [messageOwnerTypes.COMMON_INBOX, messageOwnerTypes.OTP_INBOX];
      const isProfileInThread = this.shownSubscription.recipients.some(threadRecipient => {
        if (
          messageOwnerTypesToSkip.includes(threadRecipient.mailBoxOwner.mailBoxOwnerType) === true ||
          threadRecipient.leaveTime != null
        ) {
          return false;
        }
        return threadRecipient.mailBoxOwner.profileId == recipient.profileId;
      });

      const hasAddedRecipientToRecipients = this.recipients.some(
        addedRecipient => addedRecipient.profileId === recipient.profileId
      );
      return isProfileInThread === true || hasAddedRecipientToRecipients === true;
    },
    hasCommonInboxBeenAddedAsRecipient(recipient) {
      if (recipient.id == null) {
        return false;
      }
      const isMessageOwnerInThread = this.shownSubscription.recipients.some(threadRecipient => {
        if (
          threadRecipient.mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.COMMON_INBOX ||
          threadRecipient.leaveTime != null
        ) {
          return false;
        }
        return threadRecipient.mailBoxOwner.id == recipient.id;
      });

      const hasAddedMessageOwner = this.recipients.some(addedRecipient => addedRecipient.id === recipient.id);
      return isMessageOwnerInThread === true || hasAddedMessageOwner === true;
    },
    hasOtpInboxBeenAddedAsRecipient(recipient) {
      if (recipient.otpInboxId == null) {
        return false;
      }
      const isOtpInThread = this.shownSubscription.recipients.some(threadRecipient => {
        if (
          threadRecipient.mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.OTP_INBOX ||
          threadRecipient.leaveTime != null
        ) {
          return false;
        }
        return threadRecipient.mailBoxOwner.id == recipient.otpInboxId;
      });

      const hasAddedOtp = this.recipients.some(addedRecipient => addedRecipient.otpInboxId === recipient.otpInboxId);
      return isOtpInThread === true || hasAddedOtp === true;
    },
    hideAddRecipientsModal() {
      this.$refs.addRecipientsModalVisible.hide();
      this.resetAddRecipientsInput = true;
      this.showAlertNoParticipants = false;
      this.iniviteExistingRecipients = [];
      this.$nextTick(() => (this.resetAddRecipientsInput = false));
    },
    pushAndMergeSubGroupsIntoOneGroup(groupRecipient) {
      if (groupRecipient.type !== memberTypes.GROUP) {
        return;
      }
      const isRecipientFromSameSubGroup = this.recipientsFromGroups.some(
        rec => rec.groupId === groupRecipient.id && rec.portalRole === groupRecipient.portalRole
      );
      if (isRecipientFromSameSubGroup) {
        return;
      }

      const groupDict = {};
      for (const groupSharing of [...this.recipientsFromGroups, groupRecipient]) {
        const isNewlyAddedGroup = groupSharing.id != null;
        if (isNewlyAddedGroup) {
          const groupDictItem = groupDict[groupSharing.id] || {};
          const portalRoles = groupDictItem.portalRoles || [];
          const portalRole = groupSharing.portalRole; // only new added subgroup has this, existing group won't have this field
          const groupId = +groupSharing.id;

          if (portalRole != null && portalRoles.indexOf(portalRole) === -1) {
            portalRoles.push(portalRole);
          }
          // final format of each item
          groupDict[groupId] = {
            groupId,
            portalRoles: [...portalRoles],
          };
        } else {
          groupDict[groupSharing.groupId] = {
            groupId: groupSharing.groupId,
            portalRoles: groupSharing.portalRoles,
          };
        }
      }
      this.recipientsFromGroups = Object.values(groupDict);
    },
    filterInviteeGroups(inviteeGroups, blockedGroups) {
      const filteredInviteeGroups = [];
      for (const inviteeGroup of inviteeGroups) {
        const filteredBlockedGroups = blockedGroups.filter(
          blockedGroup => blockedGroup.blockedGroup.id === inviteeGroup.groupId
        );
        const blockedPortalRoles = filteredBlockedGroups.map(blockedGroup => blockedGroup.portalRole);
        const filteredPortalRoles = inviteeGroup.portalRoles.filter(
          portalRole => blockedPortalRoles.indexOf(portalRole) === -1
        );

        if (filteredPortalRoles.length === 0) {
          continue;
        }

        filteredInviteeGroups.push({
          ...inviteeGroup,
          portalRoles: filteredPortalRoles,
        });
      }
      return filteredInviteeGroups;
    },
    async addRecipients(skipBlockCheck = false) {
      this.blockedProfiles = [];
      this.blockedOtps = [];
      this.blockedGroups = [];

      const profileRecipients = this.recipients.filter(recipient => recipient.otpInboxId == null);
      const otpRecipients = this.recipients.filter(recipient => recipient.otpInboxId != null); // otp in recipients here has portalRole guardian
      const otpInboxIds = otpRecipients.map(otp => otp.otpInboxId);
      const groupRecipients = this.recipientsFromGroups;

      const showWarning = this.recipients.length === 0 && this.recipientsFromGroups.length === 0;
      this.showAlertNoParticipants = showWarning;
      if (showWarning === false) {
        this.isLoading = true;
        let results = [];
        if (!skipBlockCheck) {
          results = await this.resolveBlockedUsers({
            creator: this.profile.id,
            profiles: profileRecipients,
            otpInboxIds: otpInboxIds,
            groupSharings: groupRecipients,
          });
        }
        if (results.length > 0) {
          // Both can contain OTP
          const blockedUsers = results.filter(x => x.institutionProfile || x.otpInbox);
          const blockedGroups = results.filter(x => !x.institutionProfile && !x.otpInbox);

          if (blockedGroups.length > 0) {
            this.blockedGroups = [...new Set(blockedGroups)].filter(x => x.portalRole !== portalRoles.OTP);
          }
          if (blockedUsers.length > 0) {
            this.blockedProfiles = blockedUsers.filter(
              (value, index, self) =>
                value.portalRole !== portalRoles.OTP &&
                self.map(x => x.institutionProfile?.id).indexOf(value.institutionProfile?.id) === index
            );
          }
          const isOtpBlocked =
            blockedGroups.some(x => x.portalRole === portalRoles.OTP) ||
            blockedUsers.some(x => x.portalRole === portalRoles.OTP);
          if (isOtpBlocked) {
            this.blockedOtps = blockedUsers.filter(x => x.otpInbox);
          }

          this.$refs.theCommunicationChannelIsBlocked.show();
          this.isLoading = false;
        } else {
          this.existingRecipients = this.recipients.filter(recipient =>
            this.shownSubscription.recipients.find(subscription => subscription.address === recipient.aulaEmail)
          );
          this.existingRecipients = this.existingRecipients.concat(this.recipientsFromGroups);
          const mailboxOwnerDtos = this.recipients.map(r => ({
            id: r.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX ? r.otpInboxId : r.id,
            mailBoxOwnerType: r.mailBoxOwnerType,
          }));
          this.addRecipientsToThread({
            recipients: mailboxOwnerDtos,
            subscriptionId: this.shownSubscription.id,
            commonInboxId:
              this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX
                ? this.chosenFolderAndMailOwner.mailOwnerId
                : null,
            otpInboxId:
              this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
                ? this.chosenFolderAndMailOwner.mailOwnerId
                : null,
          })
            .then(() => {
              this.hideAddRecipientsModal();
              this.scrollMessagesToBottom();
              this.isLoading = false;
            })
            .catch(error => {
              this.hideAddRecipientsModal();
              Vue.nextTick(function () {
                if (error.status.subCode === 5) {
                  this.$refs.theCommunicationChannelIsBlocked.show();
                }
              });
              this.isLoading = false;
            });
        }
      }
    },
    showAddRecipientsModal() {
      if (this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        this.$refs.addRecipientsModalVisible.show();
      }
    },
    forwardThread(subscriptionId) {
      if (this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        let mailBoxOwner = this.profile.id;
        if (this.shownSubscription.mailBoxOwner) {
          mailBoxOwner = this.shownSubscription.mailBoxOwner.id;
        }
        this.$router.push({
          name: 'forwardThread',
          params: { subscriptionId: subscriptionId, mailOwner: mailBoxOwner },
        });
      }
    },
    scrollMessagesToBottom() {
      const el = this.$el.closest('.conversation-holder');
      el.scrollTop = el.scrollHeight;
      if (window.innerWidth < 450) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
          window.scrollTo(0, el.scrollHeight - 150);
        } else {
          setTimeout(function () {
            $('html').animate({ scrollTop: el.scrollHeight - 150 });
          }, 500);
        }
      }
    },
    resetBlockedCommunicationModal() {
      this.blockedCommunicationToAllProfiles = false;
    },
    cancelPreviousLoadMessagesRequest() {
      this.cancelAxiosRequest(this.loadMessagesCancelTokenSource);
    },
    generateLoadMessagesCancelTokenSource() {
      this.loadMessagesCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetLoadMessagesCancelTokenSource() {
      this.loadMessagesCancelTokenSource = null;
    },
    resetSubscription() {
      this.resetSelectedSubscription();
      this.$router.push({ name: 'messages' });
    },
  },
  mounted() {
    this.selectedThread = this.subscriptionsAndBundleThreads.find(
      subscription => +subscription.id === +this.shownSubscription.id
    );
    this.setBlockedCommunicationProfiles().then(() => {
      if (this.blockedCommunicationProfiles.isBlockedAllProfileTypes == true) {
        this.blockedCommunicationToAllProfiles = true;
      }
    });
  },
  components: {
    AulaSearchRecipients,
    'move-to-folder-modal': MessageMoveToFolderModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/modal.scss';

.subscription-functionalities {
  background-color: $color-grey-light;
  top: 60px;
  @include breakpoint-lg {
    top: 0;
  }
  ul {
    max-width: 450px;
    margin: 0 auto;
    @include breakpoint-lg-down() {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.nav-item {
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    i {
      padding-right: 8px;
    }
  }
  span {
    font-size: 12px;
    color: $color-help-text;
  }
  .dropdown-item {
    span {
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
}

.b-nav-dropdown {
  &.show {
    /deep/ .nav-link,
    /deep/ .nav-link:hover,
    /deep/ .nav-link span,
    /deep/ .nav-link span:hover {
      .theme-guardian & {
        color: $color-primary-base-guardian;
      }
      .theme-child & {
        color: $color-primary-base-child;
      }
      .theme-employee & {
        color: $color-primary-base-employee;
      }
    }
  }
}

.b-nav-dropdown {
  /deep/ a {
    position: relative;
    z-index: 999;
    padding: 0.6rem;
    padding-left: 1.2rem !important;
  }
  .icon-Aula_elipses {
    vertical-align: middle;
  }
  /deep/ .dropdown-menu {
    min-width: 200px;
    padding-top: 3rem;
  }
  /deep/ .dropdown-menu-right {
    z-index: 998;
    width: fit-content;
  }
  .dropdown-item {
    padding-left: 0.5rem;
    i {
      width: 20px;
      display: inline-block;
      text-align: center;
    }
    a:first-child {
      margin-top: 0.75rem;
      padding-left: 1.35rem !important;
    }
  }
  /deep/ > div {
    min-width: 13em;
  }
  &.dropdown-select {
    text-align: left;
  }
}

.modal-content-text {
  .aula-search::after {
    font-family: aula;
    content: '\e913';
    position: absolute;
    right: 60px;
    font-size: 18px;
    top: calc(50% - 3px);
    @include breakpoint-sm-down() {
      right: 50px;
    }
  }
}

.colored {
  color: $color_alert;
}

.mark-important-text {
  display: inline-block;
  width: 45px;
  text-align: left;
}

.mark-read-unread-text {
  display: inline-block;
  min-width: 75px;
  text-align: left;
  @include breakpoint-lg-down() {
    min-width: 40px;
  }
}

a:hover {
  color: initial;
  @include breakpoint-lg() {
    .icon-Aula_star:before {
      color: $color-grey;
    }
    .icon-Aula_star_solid:before {
      color: $color-alert;
    }
  }
}

.theme-guardian {
  a:hover {
    span {
      color: $color-primary-darker-guardian;
    }
  }
}

.theme-employee {
  a:hover {
    span {
      color: $color-primary-darker-employee;
    }
  }
}

.theme-child {
  a:hover {
    span {
      color: $color-primary-darker-guardian;
    }
  }
}
</style>
