var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"onboarding-step2"},[_c('div',{staticClass:"step2-content scrollbar"},_vm._l((_vm.institutionProfiles),function(profile,profileIndex){return _c('b-row',{key:'profile' + profile.profileId},[(profile.institutionProfiles.filter(function (p) { return p.isEdit == true; }).length > 0)?_c('b-col',{staticClass:"portal-roles",attrs:{"cols":"12"}},[_c('h1',[(profile.portalRole == _vm.portalRoles.GUARDIAN)?[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('PARENT'))+"\n          ")]:_vm._e(),_vm._v(" "),(profile.portalRole == _vm.portalRoles.EMPLOYEE)?[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('ONBOARDING_EMPLOYEE_PROFILES'))+"\n          ")]:_vm._e()],2)]):_vm._e(),_vm._v(" "),(_vm.canChangePrimaryInstitution)?_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('ContactInformationPrimaryInstitution',{staticClass:"primary-institution",attrs:{"institutions":_vm.institutions},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm._f("fromTextKey")('ONBOARDING_CHOOSE_PRIMARY_INSTITUTION'))+" "+_vm._s(_vm.roleLabel))])]},proxy:true}],null,true),model:{value:(_vm.primaryInstitutionProfileId),callback:function ($$v) {_vm.primaryInstitutionProfileId=$$v},expression:"primaryInstitutionProfileId"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-100"}),_vm._v(" "),_vm._l((profile.institutionProfiles),function(institutionProfile,instProfileIndex){return [(
            profile.institutionProfiles != null && profile.institutionProfiles.length > 0 && institutionProfile.isEdit
          )?_c('b-col',{key:profile.profileId + institutionProfile.id,staticClass:"margin-bottom-40",attrs:{"cols":"12","md":"12","lg":"6"}},[_c('onboarding-update-institution-profile',{attrs:{"is-child":profile.portalRole === _vm.portalRoles.CHILD,"show-inst-name":profile.institutionProfiles.length > 1,"inst-profile":institutionProfile,"profile-index":profileIndex,"institution-profile-index":instProfileIndex},on:{"disableSubmitButton":_vm.disableSubmitButton}})],1):_vm._e()]}),_vm._v(" "),(profile.portalRole === _vm.portalRoles.GUARDIAN)?_c('b-col',{attrs:{"cols":"12"}}):_vm._e(),_vm._v(" "),_vm._l((profile.children),function(child,childIndex){return [(
            profile.portalRole == _vm.portalRoles.GUARDIAN &&
            profile.children != null &&
            profile.children.length > 0 &&
            child.institutionProfile.newInstitutionProfile
          )?_c('b-col',{key:'child' + childIndex,staticClass:"margin-bottom-40",attrs:{"cols":"12","md":"12","lg":"6"}},[_c('onboarding-update-institution-profile',{attrs:{"is-child":true,"inst-profile":child.institutionProfile,"profile-index":profileIndex,"child-index":childIndex},on:{"disableSubmitButton":_vm.disableSubmitButton}})],1):_vm._e()]})],2)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }