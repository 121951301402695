<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    v-button
    :class="{
      selected: subscription.checked,
      'multiple-selected': subscriptionPressed,
    }"
    @click="onClickContainer"
  >
    <div class="position-relative">
      <div ref="swipeButtons" class="swipe-buttons" :class="subscription.draft ? 'is-draft' : 'is-not-draft'">
        <!-- We sacrifice UX in order to be able to navigate to the message thread for screen reader users -->
        <button type="button" class="sr-only" @click.stop="onClickContainer">
          {{ 'MESSAGE_OPEN_MESSAGE' | fromTextKey }}
        </button>
        <button
          v-if="!subscription.draft"
          type="button"
          class="swipe-button swipe-button-slot-1"
          @click.stop="onChangeSubscriptionStatus"
        >
          <i :class="subscriptionRead ? 'icon-Aula_envelope_closed' : 'icon-Aula_envelope_open'" />
          <span class="button-text">
            {{ subscriptionRead ? 'MESSAGE_MARK_AS_UNREAD_SHORT' : 'MESSAGE_MARK_AS_READ_SHORT' | fromTextKey }}
          </span>
        </button>
        <button type="button" class="swipe-button swipe-button-slot-2" @click.stop="deleteBtnClicked">
          <i class="icon-Aula_bin" />
          <span class="button-text">
            {{ 'MESSAGE_DELETE' | fromTextKey }}
          </span>
        </button>
        <div v-if="!subscription.draft" class="swipe-button swipe-button-slot-3">
          <b-dropdown ref="moreOptionsDropdown" class="swipe-dropdown p-0" no-caret>
            <template #button-content>
              <i class="icon-Aula_elipses" />
              <div class="button-text">
                {{ 'MESSAGE_DROPDOWN_MORE' | fromTextKey }}
              </div>
            </template>
            <b-dropdown-item v-if="canMoveToFolder" @click.stop="changeMoveToFolderVisibility(true)">
              <div class="d-flex align-items-center">
                <i class="icon-Aula_folder" />
                <div class="button-text mt-0 ml-2">
                  {{ 'MESSAGE_MOVE_FOLDER' | fromTextKey }}
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click.stop="markSubscriptionToggle(subscription)">
              <div class="d-flex align-items-center">
                <i :class="subscriptionMarked ? 'icon-Aula_star_solid colored' : 'icon-Aula_star'" />
                <div class="button-text mt-0 ml-2">
                  {{ subscriptionMarked ? 'MESSAGE_REMOVE_MARK_LONG' : 'MESSAGE_MARK_LONG' | fromTextKey }}
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="canForwardThread" @click.stop="handleForwardThread">
              <div class="d-flex align-items-center">
                <i class="icon-Aula_redirect" />
                <div class="button-text mt-0 ml-2">
                  {{ 'MESSAGE_FORWARDING' | fromTextKey }}
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <v-touch ref="touchArea" class="touch-area" @swipeleft="onSwipeLeft" @press="onPress" @swiperight="onSwipeRight">
      <component :is="threadItemElement" tag="div" :to="subscriptionLink">
        <div class="card thread-item thread-item-mobile" :class="{ unread: !subscriptionRead }">
          <div v-if="subscription.subscriptionType === subscriptionTypes.BUNDLE" class="bundle-expand-icon">
            <i
              :class="{
                open: isBundleOpen,
              }"
              class="icon icon-Aula_down-arrow"
            />
          </div>
          <template v-if="subscriptionPressed || isMultipleItemsSelected">
            <b-form-checkbox
              v-if="!inSearch"
              v-model="subscription.checked"
              class="in-front"
              @change="onCheckBoxClicked"
              @click.native="$event.stopPropagation()"
            />
          </template>
          <div class="card-block subscription-area">
            <div>
              <div>
                <template v-if="latestMessageDatetime != null">
                  <post-time class="last-message-sent-time pull-left" :time="latestMessageDatetime" />
                </template>
                <span v-if="subscriptionMarked" class="marked mobile">
                  <i class="icon-Aula_star_solid small_star colored" />
                </span>
              </div>
              <div class="list-of-participants truncate" :title="firstDisplayedParticipantText">
                <keyword-highlight class="participant-name" :queries="messageSearchQuery">
                  {{ firstDisplayedParticipantText }}
                </keyword-highlight>
                <div v-if="extraRecipientsCount > 0" class="pl-1">+{{ extraRecipientsCount }}</div>
              </div>
            </div>
            <div class="list-of-children-images">
              <user-icon
                v-for="(child, index) in subscription.regardingChildren"
                :id="child.id"
                :key="index"
                :name="child.fullName"
                :short-name="child.shortName"
                :show-inline="true"
                :picture-object="child.profilePicture"
                user-icon-class="small"
              />
              <user-icon
                v-if="
                  institutions.find(inst => inst.institutionCode == subscription.institutionCode) != null &&
                  profile.role != portalRoles.GUARDIAN &&
                  institutions.length > 1
                "
                :name="institutions.find(inst => inst.institutionCode == subscription.institutionCode).name"
                :short-name="institutions.find(inst => inst.institutionCode == subscription.institutionCode).shortName"
                :show-inline="true"
                user-icon-class="small"
              />
            </div>
          </div>
          <div v-if="isSubjectVisible" class="thread-subject">
            <keyword-highlight :queries="messageSearchQuery">
              {{ subscription.subject }}
            </keyword-highlight>
          </div>
          <div v-if="subscriptionMuted" class="text-center icon-container icon-mute-mobile">
            <i class="icon-Bell_2" />
          </div>
          <div :id="threadSubjectIdPrefix + subscription.id" class="thread-description">
            <template v-if="subscription.subscriptionType === subscriptionTypes.BUNDLE">
              <div class="bundle-items-total">
                {{ numberOfBundleItems }}
                {{
                  numberOfBundleItems === 1 ? 'MESSAGE_THREAD_TEXT_SINGLE' : 'MESSAGE_THREAD_TEXT_PLURAL' | fromTextKey
                }}
              </div>
            </template>
            <template v-else>
              <div v-if="requiresStepUp" class="sensitive-thread-subject">
                <i class="icon-Aula_lock selected" />
                <span>{{ 'MESSAGE_MESSAGE_SENSITIVE' | fromTextKey }}</span>
                <div class="sensitive-line" />
              </div>
              <div v-else class="subscription-last-text">
                <span v-if="subscription.draft" class="subscription-draft-label-prefix badge badge-danger">{{
                  'MESSAGE_TOOLBAR_FILTER_DRAFT' | fromTextKey
                }}</span>
                <template v-if="subscription.leaveTime == null">
                  <span
                    v-if="
                      !subscription.draft &&
                      subscriptionLatestMessage != null &&
                      subscriptionLatestMessage.text != undefined
                    "
                  >
                    <keyword-highlight :queries="messageSearchQuery">{{
                      $options.filters.stripHTML(subscriptionLatestMessage.text.html)
                    }}</keyword-highlight>
                  </span>
                  <span v-if="subscription.draft">
                    <keyword-highlight :queries="messageSearchQuery">{{
                      $options.filters.stripHTML(subscription.text)
                    }}</keyword-highlight>
                  </span>
                </template>
                <div
                  v-if="subscription.leaveTime != null && subscription.subscriptionType !== subscriptionTypes.BUNDLE"
                >
                  {{ 'MESSAGE_LEAVE_CONVERSATION' | fromTextKey }}
                </div>
              </div>
            </template>
          </div>
          <div class="text-center icon-container">
            <div class="secure-file-icon" />
            <!--TODO implement secure file icon-->
            <i v-if="isSubscriptionSensitive" class="icon-Aula_lock selected" />
          </div>
        </div>
      </component>
    </v-touch>
    <aula-modal
      ref="deleteModal"
      header-text="MESSAGE_DELETE_MORE_THREADS_HEADER"
      ok-text="MESSAGE_DELETE_OK"
      data-size="small"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteThisThread"
    >
      <template v-if="subscription.subscriptionType === subscriptionTypes.BUNDLE">
        {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_1' | fromTextKey }}<br />
        {{ deletedThreadWarningMessage }} <br />
        {{ 'MESSAGE_DELETE_MORE_THREADS_WARNING_2' | fromTextKey }}
      </template>
      <template v-else>
        {{ 'MESSAGE_DELETE_THREAD_INFO_1' | fromTextKey }}<br />
        {{ deletedThreadWarningMessage }} <br />
        {{ 'MESSAGE_DELETE_THREAD_INFO_3' | fromTextKey }}
      </template>
    </aula-modal>
    <aula-modal
      ref="forwardWarningModal"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.forwardWarningModal.hide()"
      @okClicked="
        $refs.forwardWarningModal.hide();
        forwardThread(subscription.id);
      "
    >
      {{ 'WARNING_FORWARD_MESSAGE' | fromTextKey }}
    </aula-modal>
    <move-to-folder-modal
      v-if="showMoveToFolder"
      :subscription-ids="[subscription.subscriptionId]"
      :chosen-folder-and-mail-owner="chosenFolderAndMailOwner"
      :folders="!!chosenFolderAndMailOwner.mailOwnerId ? commonInboxFolders : folders"
      @hide="changeMoveToFolderVisibility"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { types } from '../../store/types/types';
import moment from 'moment-timezone';
import VueTouch from 'vue-touch';
import Vue from 'vue';
import MessageMoveToFolderModal from './MessageMoveToFolderModal.vue';
import KeywordHighlight from 'vue-text-highlight';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import UserIcon from '../users/UserIcon.vue';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { sensitivityLevel } from '../../../shared/enums/sensitivityLevel';
import { portalRoles } from '../../../shared/enums/portalRoles';
import $ from 'jquery';
import { subscriptionStatus } from '../../../shared/enums/subscriptionStatus';
import { subscriptionTypes } from '../../../shared/enums/subscriptionTypes';
import { messageFiltersEnum } from '../../../shared/enums/messageFiltersEnum';
import messageSubscriptionMixin from '../../../shared/mixins/messageSubscriptionMixin';
import { profileUtil } from '../../../shared/utils/profileUtil';
import { messageUtil } from '../../../shared/utils/messageUtil';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import Button from '../../../shared/directives/button';
import { ThreadType } from '../../../shared/enums/threadType';

VueTouch.config.swipe = {
  direction: 'horizontal',
};

export default {
  directives: {
    Button,
  },
  mixins: [messageSubscriptionMixin],
  inject: {
    getCanMoveToDeletedMessageFolder: messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER,
  },
  props: {
    subscription: Object,
    isBundleOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      moment: moment,
      portalRoles: portalRoles,
      institutionRole: institutionRole,
      sensitivityLevel: sensitivityLevel,
      messageOwnerTypes: messageOwnerTypes,
      showMoveToFolder: false,
      threadSubjectIdPrefix: 'thread-last-text-',
      participantNamesPrefix: 'participant-names-',
      cardBlockPrefix: 'card-block-',
      threadSubjectPrefix: 'thread-subject-',
      swipeButtonWidth: 51,
      subscriptionTypes,
    };
  },
  computed: {
    ...mapGetters({
      notifications: types.GET_NOTIFICATIONS,
      subscriptionPressed: types.MESSAGES_GET_SUBSCRIPTIONS_ONPRESS_MOBILEVIEW,
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
      folders: types.MESSAGES_GET_FOLDERS,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
      hasPermission: types.HAS_PERMISSION,
      inSearch: types.MESSAGES_GET_IN_SEARCH,
      messageSearchQuery: types.MESSAGES_GET_ACTIVE_SEARCH_QUERY,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
      threadBundles: types.MESSAGES_GET_THREAD_BUNDLES,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
    }),
    isReminderThread() {
      const threadType = this.subscription.threadEntityLinkDto?.threadType ?? this.subscription.thread.threadType;

      return [ThreadType.EVENT_REMINDER, ThreadType.VACATION_REQUEST_REMINDER].includes(threadType);
    },
    canMoveToDeletedMessageFolder() {
      return this.getCanMoveToDeletedMessageFolder();
    },
    deletedThreadWarningMessage() {
      let message =
        this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
          ? this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2')
          : this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2');

      if (this.chosenFolderAndMailOwner?.mailOwnerId) {
        message =
          this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
            ? this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2_COMMON')
            : this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_2_COMMON');
      }
      if (this.canMoveToDeletedMessageFolder && !this.subscription.draft) {
        message +=
          this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
            ? ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_FROM_NORMAL_FOLDER')}`
            : ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_MORE_THREADS_INFO_FROM_NORMAL_FOLDER')}`;
      }
      return message;
    },
    threadItemElement() {
      if (this.subscription.subscriptionType !== subscriptionTypes.BUNDLE && !this.subscriptionPressed) {
        return 'router-link';
      }
      return 'div';
    },
    firstDisplayedParticipantText() {
      const recipient = messageUtil.getFirstParticipant(
        this.subscription.recipients,
        this.chosenFolderAndMailOwner,
        this.profile
      );
      if (recipient) {
        return this.$options.filters.displayProfileNameWithMetadata(recipient);
      }
      return '';
    },
    extraRecipientsCount() {
      return messageUtil.getExtraRecipientsCount(this.subscription);
    },
    canUseSecureLoginService() {
      return profileUtil.getCanUseSecureLoginServices({ profile: this.profile });
    },
    isMultipleItemsSelected() {
      return this.selectedThreadIds.length + this.selectedBundleIds.length >= 2;
    },
    isSubjectVisible() {
      if (this.requiresStepUp) {
        return false;
      }
      return (
        this.subscription.subscriptionType !== subscriptionTypes.BUNDLE_ITEM ||
        this.chosenFolderAndMailOwner.filter !== messageFiltersEnum.FILTER_ALL
      );
    },
    numberOfBundleItems() {
      if (this.subscription.subscriptionType !== subscriptionTypes.BUNDLE) {
        return null;
      }

      const bundleData = this.threadBundles.find(bundle => bundle.bundleId === this.subscription.subscriptionId);
      if (bundleData) {
        return bundleData.numberOfBundleItems;
      }

      return this.subscription.numberOfBundleItems;
    },
    isAllBundleItemsSelected() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE) {
        const threadIdsInBundle = this.threadsInBundle.map(thread => thread.id);
        return threadIdsInBundle.every(id => this.selectedThreadIds.includes(id)) && threadIdsInBundle.length > 0;
      }
      return false;
    },
    threadsInBundle() {
      const bundle = this.threadBundles.find(bundle => bundle.bundleId === this.subscription.subscriptionId);
      if (bundle) {
        return bundle.threads.filter(thread => !thread.deleted);
      }
      return [];
    },
    subscriptionQuery() {
      let query = {};
      if (this.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX) {
        query = {
          folderId: this.chosenFolderAndMailOwner.folderId,
          mailOwnerId: this.chosenFolderAndMailOwner.mailOwnerId,
          mailBoxOwnerType: messageOwnerTypes.COMMON_INBOX,
        };
      } else if (this.profile.role === portalRoles.OTP) {
        if (!this.subscription.draft) {
          query = {
            otpInboxId: this.subscription.mailBoxOwner.id,
          };
        }
      }
      return query;
    },
    subscriptionLink() {
      return {
        name: 'uniqueThread',
        params: {
          id: this.subscription.id,
          folderId: this.subscription.folderId,
          mailBoxType: this.subscription.mailBoxOwnerType,
          mailBoxId: this.subscription.mailBoxOwnerId,
        },
        query: this.subscriptionQuery,
      };
    },
    requiresStepUp() {
      return this.subscription.sensitive && !this.isSteppedUp;
    },
    canMoveToFolder() {
      return this.hasPermission(permissionEnum.INBOX_FOLDERS);
    },
    hasPermissionToWriteMessage() {
      return this.hasPermission(permissionEnum.WRITE_MESSAGE);
    },
    canForwardThread() {
      if ((this.requiresStepUp && !this.canUseSecureLoginService) || this.isReminderThread) {
        return false;
      }

      return (
        this.hasPermissionToWriteMessage === true &&
        this.subscription.isArchived !== true &&
        this.subscription.subscriptionType !== subscriptionTypes.BUNDLE
      );
    },
    subscriptionRead() {
      if (this.subscription.draft) {
        return true;
      }
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.every(thread => thread.read === true);
      }
      return this.subscription.read;
    },
    subscriptionMarked() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.some(thread => thread.marked === true);
      }
      return this.subscription.marked;
    },
    subscriptionMuted() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.every(thread => thread.muted === true);
      }
      return this.subscription.muted;
    },
    subscriptionLatestMessage() {
      return this.subscription.latestMessage;
    },
    latestMessageDatetime() {
      const bundleData = this.threadBundles.find(bundle => bundle.bundleId === this.subscription.subscriptionId);
      if (bundleData == null) {
        return this.subscriptionLatestMessage?.sendDateTime || this.subscription.startedTime;
      }

      const bundleThreadsSendTimestamps = bundleData.threads.map(thread => thread.latestMessage.sendDateTime);
      bundleThreadsSendTimestamps.sort((a, b) => new Date(b) - new Date(a));
      return bundleThreadsSendTimestamps[0];
    },
    isSubscriptionSensitive() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE && this.threadsInBundle.length > 0) {
        return this.threadsInBundle.some(thread => thread.sensitivityLevel === sensitivityLevel.HIGH);
      }
      return this.subscription.sensitivityLevel === sensitivityLevel.HIGH && !this.requiresStepUp;
    },
  },
  methods: {
    ...mapActions({
      switchMarkMessages: types.SWITCH_MARK_SUBSCRIPTIONS,
      deleteSubscriptions: types.DELETE_SUBSCRIPTIONS,
      changeSubscriptionPressed: types.SUBSCRIPTIONS_ONPRESS_MOBILEVIEW,
      deleteNotifications: types.DELETE_NOTIFICATIONS,
      updateSubscriptionStatus: types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
      moveThreadsToFolder: types.MOVE_THREADS_TO_FOLDER,
      leaveThread: types.MESSAGES_LEAVE_THREADS,
      deleteSubscription: types.DELETE_SUBSCRIPTIONS,
    }),
    ...mapMutations({
      deleteSubscriptionById: types.MUTATE_DELETE_SUBSCRIPTION_BY_ID,
      updateSubscriptionCheckedStatus: types.MUTATE_SUBSCRIPTIONS_CHECKED_STATUS,
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
      mutateDeleteSubscription: types.MUTATE_DELETE_SUBSCRIPTIONS,
      mutateDeleteDraftThread: types.MUTATE_DELETE_DRAFT_THREAD,
    }),
    onClickContainer() {
      if (this.subscription.subscriptionType === subscriptionTypes.BUNDLE) {
        return;
      }

      if (this.subscriptionPressed) {
        this.onCheckBoxClicked(!this.subscription.checked);
        return;
      }

      this.$router.push(this.subscriptionLink);
    },
    handleForwardThread() {
      const subscriptionId = this.subscription.id;
      if (this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        let mailBoxOwner = this.profile.id;
        if (this.subscription.mailBoxOwner) {
          mailBoxOwner = this.subscription.mailBoxOwner.id;
        }
        this.$router.push({
          name: 'forwardThread',
          params: { subscriptionId, mailOwner: mailBoxOwner },
        });
      }
      this.onSwipeRight();
    },
    async onChangeSubscriptionStatus() {
      this.onSwipeRight();
      await this.updateSubscriptionStatus({
        subscriptionIds: [this.subscription.subscriptionId],
        status: this.subscriptionRead ? subscriptionStatus.UNREAD : subscriptionStatus.READ,
      });

      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    async deleteThisThread() {
      this.$refs.deleteModal.hide();
      if (this.subscription.draft) {
        this.mutateDeleteDraftThread({
          id: this.profile.id,
          key: this.subscription.id,
        });
        return;
      }
      if (this.profile.role === portalRoles.OTP) {
        this.chosenFolderAndMailOwner.mailOwnerId = this.subscription.mailBoxOwner.id;
        this.chosenFolderAndMailOwner.mailOwnerType = this.subscription.mailBoxOwner.mailBoxOwnerType;
      }
      if (this.canMoveToDeletedMessageFolder) {
        await this.leaveThread({
          subscriptionIds: [this.subscription.subscriptionId],
        });
        await this.moveThreadsToFolder({
          subscriptionIds: [this.subscription.subscriptionId],
          folderId: this.deletedFolderId,
        });
        this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER');
        this.mutateDeleteSubscription({
          threadIds: this.subscription.subscriptionType !== subscriptionTypes.BUNDLE ? [this.subscription.id] : [],
          subscriptionIds:
            this.subscription.subscriptionType === subscriptionTypes.BUNDLE ? [this.subscription.subscriptionId] : [],
          id: this.profile.id,
        });
      } else {
        this.deleteSubscription({
          subscriptionIds:
            this.subscription.subscriptionType === subscriptionTypes.BUNDLE ? [this.subscription.subscriptionId] : [],
          threadIds: this.subscription.subscriptionType !== subscriptionTypes.BUNDLE ? [this.subscription.id] : [],
        });
      }
      this.onSwipeRight();
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    deleteNotificationsForThread(threadId) {
      const notificationsLocal = [];
      for (const notification of this.notifications) {
        if (notification.threadId === threadId) {
          notificationsLocal.push({
            notificationId: notification.notificationId,
            institutionProfileId: notification.institutionProfileId,
          });
        }
      }
      if (notificationsLocal.length > 0) {
        this.deleteNotifications({ notifications: notificationsLocal });
      }
    },
    markSubscriptionToggle(subscription) {
      this.switchMarkMessages({
        subscriptionIds: [subscription.subscriptionId],
        isMarked: !subscription.marked,
      }).then(() => {
        this.onSwipeRight();
      });
    },
    getParticipantNames(subscriptions) {
      let value = subscriptions.map(item => Vue.filter('displayProfileNameWithMetadata')(item)).join(', ');
      if (this.subscription.extraRecipientsCount) {
        value += ' +' + this.subscription.extraRecipientsCount;
      }
      return value;
    },
    deleteBtnClicked() {
      this.$refs.deleteModal.show();
    },
    changeMoveToFolderVisibility(value) {
      this.showMoveToFolder = value;
    },
    onSwipeLeft() {
      const swipeButtonAreaWidth = `-${this.swipeButtonWidth * 3}px`;
      const swipeButtonAreaWidthForDraft = `-${this.swipeButtonWidth}px`;

      $('.touch-area.is-opened').animate({ left: 0 });
      $('.swipe-buttons.is-not-draft.is-opened').animate({
        right: swipeButtonAreaWidth,
      });
      $('.swipe-buttons.is-draft.is-opened').animate({
        right: swipeButtonAreaWidthForDraft,
      });
      $('.is-opened').removeClass('is-opened');

      $(this.$refs.touchArea.$el).addClass('is-opened');
      $(this.$refs.swipeButtons).addClass('is-opened');
      if (this.subscription.draft) {
        $(this.$refs.touchArea.$el).animate({
          left: swipeButtonAreaWidthForDraft,
        });
      } else {
        $(this.$refs.touchArea.$el).animate({ left: swipeButtonAreaWidth });
      }
      $(this.$refs.swipeButtons).animate({ right: '0px' });
    },
    onSwipeRight() {
      const swipeButtonAreaWidth = `-${this.swipeButtonWidth * 3}px`;
      const swipeButtonAreaWidthForDraft = `-${this.swipeButtonWidth}px`;

      $(this.$refs.touchArea.$el).removeClass('is-opened');
      $(this.$refs.swipeButtons).removeClass('is-opened');

      if (this.$refs.moreOptionsDropdown) {
        this.$refs.moreOptionsDropdown.hide();
      }
      $(this.$refs.touchArea.$el).animate({ left: '0px' });
      $(this.$refs.swipeButtons).animate({
        right: this.subscription.draft ? swipeButtonAreaWidthForDraft : swipeButtonAreaWidth,
      });
    },
    onPress() {
      this.changeSubscriptionPressed(true);
    },
  },
  watch: {
    isAllBundleItemsSelected(value) {
      this.updateSubscriptionCheckedStatus({
        subscriptionId: this.subscription.subscriptionId,
        checked: value,
      });
      this.updateBundleIdToStore(value);
    },
    numberOfBundleItems(value) {
      if (value === 0) {
        this.deleteSubscriptionById({ subscriptionId: this.subscription.subscriptionId });
      }
    },
  },
  components: {
    'move-to-folder-modal': MessageMoveToFolderModal,
    UserIcon,
    KeywordHighlight,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/messages/_subscriptions.scss';

.swipe-buttons {
  @include breakpoint-sm-down() {
    font-size: 8px !important;

    .button-text {
      margin-top: 6px;
    }
  }
}

.sensitive-line {
  border-top: 3px solid $color-grey-base;
  margin-top: 7px;
}

.sensitive-thread-subject {
  margin-top: 0px;
}

.thread-description {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.threads .marked.mobile {
  font-size: 13px;
  margin-left: 5px;
  position: static;
}
</style>
