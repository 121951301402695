<template>
  <div class="user-settings-content">
    <div class="header-dropdown-title fixed">
      {{ profile.firstName + ' ' + profile.lastName }}
      <i
        class="icon-Aula_app_arrow_slideback group-back"
        :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
        tabindex="0"
        @keydown.enter="
          toggleHeaderDropdown({
            dropdown: 'mobileProfile',
            visible: showMobileProfileDropdown,
          })
        "
        @click="
          toggleHeaderDropdown({
            dropdown: 'mobileProfile',
            visible: showMobileProfileDropdown,
          })
        "
      />
      <div v-if="!hideCloseIcon" class="header-dropdown-close">
        <i
          tabindex="0"
          role="button"
          class="icon-Aula_close"
          :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
          @click="
            toggleHeaderDropdown({
              dropdown: 'profile',
              visible: showProfileDropdown,
            })
          "
          @keydown.enter="
            toggleHeaderDropdown({
              dropdown: 'profile',
              visible: showProfileDropdown,
            })
          "
        />
      </div>
    </div>
    <div class="header-dropdown-subtitle first-item">
      {{ 'MY_PROFILE_LABEL' | fromTextKey }}
    </div>
    <div class="profile-icon">
      <div
        class="item"
        tabindex="0"
        role="button"
        @click="
          toggleHeaderDropdown({
            dropdown: 'mobileProfile',
            visible: showMobileProfileDropdown,
          })
        "
        @keydown.enter="
          toggleHeaderDropdown({
            dropdown: 'mobileProfile',
            visible: showMobileProfileDropdown,
          })
        "
      >
        <user-icon
          :id="profile.id"
          :short-name="profile.shortName"
          :name="profile.firstName + ' ' + profile.lastName"
          :picture-object="profile.profilePicture"
        />
        <div class="item-title">{{ profile.firstName }} {{ profile.lastName }}</div>
      </div>
    </div>
    <div
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="navigateToProfile()"
      @keydown.enter="navigateToProfile()"
    >
      <b-row>
        <b-col cols="12" md="10">
          {{ 'CONTACT_INFO' | fromTextKey }}
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <div
      v-if="
        profile.role == portalRoles.EMPLOYEE || profile.role == portalRoles.GUARDIAN || profile.role === portalRoles.OTP
      "
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="navigateToAdditionalData()"
      @keydown.enter="navigateToAdditionalData()"
    >
      <b-row>
        <b-col cols="12" md="10">
          {{ 'ADDITIONAL_INFO_LABEL' | fromTextKey }}
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <div class="header-dropdown-title sub">
      {{ 'USERS_LABEL_SETTINGS' | fromTextKey }}
    </div>
    <div
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="navigateToNotificationSettings()"
      @keydown.enter="navigateToNotificationSettings()"
    >
      <b-row>
        <b-col cols="12" md="10">
          {{ 'USERS_LABEL_NOTIFICATION_SETTINGS' | fromTextKey }}
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <div
      v-if="profiles.length > 0 && (profiles[0].age18AndOlder == null || profiles[0].age18AndOlder)"
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="navigateToPrivacyPolicy()"
      @keydown.enter="navigateToPrivacyPolicy()"
    >
      <b-row>
        <b-col cols="12" md="10">
          {{ 'PRIVACY_POLICY' | fromTextKey }}
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <div
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="navigateToAppSettings()"
      @keydown.enter="navigateToAppSettings()"
    >
      <b-row>
        <b-col cols="12" md="10">
          {{ 'APP_SETTINGS_LABEL' | fromTextKey }}
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <div
      v-if="
        profileNextOption.length > 0 && (profile.loginPortalRole === portalRoles.EMPLOYEE || isChildWithTwoProfiles)
      "
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="switchProfile()"
      @keydown.enter="switchProfile()"
    >
      <b-row>
        <b-col cols="12" md="10">
          {{ 'SWITCH_PROFILE' | fromTextKey }}
          <template v-if="profileNextOption[0].text != null">
            {{ profileNextOption[0].text.toLowerCase() }}
          </template>
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <div
      class="header-dropdown-item"
      tabindex="0"
      role="button"
      @click="showConfirmLogout()"
      @keydown.enter="showConfirmLogout()"
    >
      <b-row>
        <b-col id="logout-link" cols="12" md="10">
          {{ 'USERS_LOGOUT' | fromTextKey }}
        </b-col>
        <b-col cols="2" class="text-right forward-icon">
          <i class="icon-Aula_forward-arrow" />
        </b-col>
      </b-row>
    </div>
    <aula-modal
      ref="modalConfirmedWarning"
      ok-text="BUTTON_YES"
      @cancelClicked="cancelWarning()"
      @okClicked="clickSubmitWarning()"
    >
      <label v-if="showSwitchProfileWarning">{{ 'MESSAGE_CONFIRM_SWITCH_PROFILE_ROLE' | fromTextKey }}</label>
      <label v-if="showLogOutWarning">{{ 'MESSAGE_CONFIRM_LOG_OUT' | fromTextKey }}</label>
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import { onboardingStatusEnum } from '../../../shared/enums/onboardingStatusEnum';

export default {
  props: {
    hideCloseIcon: { type: Boolean, default: false },
  },
  data: function () {
    return {
      portalRoles: portalRoles,
      selected: 2,
      options: [],
      showSwitchProfileWarning: false,
      showLogOutWarning: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: types.GET_CURRENT_PROFILE,
      profiles: types.GET_PROFILES_BY_LOGIN,
      institutions: types.GET_INSTITUTIONS,
      children: types.GET_CHILDREN,
      clientHeight: types.GET_CLIENT_HEIGHT,
      showProfileDropdown: types.GET_SHOW_PROFILE_DROPDOWN,
      showMobileProfileDropdown: types.GET_SHOW_MOBILE_PROFILE_DROPDOWN,
      isProfileLoaded: types.IS_PROFILE_LOADED,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
      onboardingSteps: types.GET_ONBOARDING_STEPS,
      onboardingStatus: types.IS_ONBOARDING_NEEDED,
    }),
    profileNextOption() {
      return this.profiles
        .map(profile => ({
          value: profile.portalRole,
          text: Vue.filter('fromTextKey')('SWITCH_' + profile.portalRole.toUpperCase()),
        }))
        .filter(portalRole => portalRole.value !== this.profile.role);
    },
    isChildWithTwoProfiles() {
      let result = false;
      if (this.profile.loginPortalRole === portalRoles.CHILD) {
        result =
          this.profiles.filter(
            profile => profile.portalRole === portalRoles.GUARDIAN || profile.portalRole === portalRoles.CHILD
          ).length === 2;
      }
      return result;
    },
  },
  methods: {
    ...mapActions({
      checkIsOnboardingNeeded: types.CHECK_IS_ONBOARDING_NEEDED,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      mutateAdditionalData: types.MUTATE_ADDITIONAL_DATA_FOR_ONBOARDING,
    }),
    navigateToNotificationSettings() {
      this.$router.push({ name: 'notifikation' });
      this.toggleHeaderDropdown({
        dropdown: 'profile',
        visible: this.showProfileDropdown,
      });
    },
    navigateToAppSettings() {
      this.$router.push({ name: 'appindstillinger' });
      this.toggleHeaderDropdown({
        dropdown: 'profile',
        visible: this.showProfileDropdown,
      });
    },
    navigateToProfile() {
      this.$router.push({
        name: 'userview',
        params: { id: this.profile.id },
        query: { parent: parentTypes.PROFILE },
      });
      this.toggleHeaderDropdown({
        dropdown: 'profile',
        visible: this.showProfileDropdown,
      });
    },
    navigateToPrivacyPolicy() {
      this.$router.push({ name: 'privacypolicy' });
      this.toggleHeaderDropdown({
        dropdown: 'profile',
        visible: this.showProfileDropdown,
      });
    },
    navigateToAdditionalData() {
      if (this.isSteppedUp) {
        this.$router.push({ name: 'additional-profile-data' });
        this.toggleHeaderDropdown({
          dropdown: 'profile',
          visible: this.showProfileDropdown,
        });
      } else {
        const pathToAdditionalData =
          window.location.origin + '/#' + this.$router.resolve({ name: 'additional-profile-data' }).location.path;
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: pathToAdditionalData,
        });
      }
    },
    clickSubmitWarning() {
      if (this.showSwitchProfileWarning) {
        this.changeNewProfile();
      } else if (this.showLogOutWarning) {
        this.disableImpersonationMode();
        this.logoutAndReturnLogin();
      }
      this.cancelWarning();
    },
    cancelWarning() {
      this.showSwitchProfileWarning = false;
      this.showLogOutWarning = false;
      this.$refs.modalConfirmedWarning.hide();
    },
    async changeNewProfile() {
      this.resetCookies();
      if (this.delegatedContext.institutionProfileId != null) {
        await this.setDelegatedContext();
      }
      this.getProfileContext({
        portalrole: this.profileNextOption[0].value,
        parent: parentTypes.PORTAL,
      }).then(() => {
        this.checkIsOnboardingNeeded().then(() => {
          this.mutateAdditionalData(null);
          if (this.onboardingStatus === onboardingStatusEnum.NEEDS_STEP_UP) {
            this.setStepUpNotification({
              showStepUpNotification: true,
              showStepUpForOnboarding: true,
              redirectedUrl: this.$options.filters.getSiteUrl(),
            });
          }
          if (this.onboardingStatus === onboardingStatusEnum.REQUIRED) {
            Cookie.Create('isOnboardingStep', this.onboardingSteps[0].step);
            this.$router.push({
              name: 'stepOnboarding',
              params: { step: this.onboardingSteps[0].step },
            });
          } else {
            this.$router.push({ name: 'overblik' });
          }
        });
      });
    },
    resetCookies() {
      Cookie.Erase('isOnboardingStep');
      Cookie.Erase('stepupUserMismatch', '.aula.dk');
    },
    showConfirmLogout() {
      this.showLogOutWarning = true;
      this.$refs.modalConfirmedWarning.show();
    },
    logoutAndReturnLogin() {
      this.resetCookies();
      window.location.href = '/auth/logout.php';
    },
    switchProfile() {
      this.showSwitchProfileWarning = true;
      this.$refs.modalConfirmedWarning.show();
    },
    ...mapActions({
      getProfileContext: types.ACTION_GET_PROFILE_CONTEXT,
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
      initNotifications: types.LOAD_NOTIFICATIONS,
      getProfileConsent: types.ACTION_GET_CONSENTS_RESPONSE,
      disableImpersonationMode: types.DISABLE_IMPERSONATION_MODE,
      loadImportantDates: types.LOAD_IMPORTANT_DATES,
      searchGlobally: types.LOAD_GLOBAL_SEARCH_RESULTS,
      setDelegatedContext: types.SET_DELEGATED_CONTEXT,
    }),
    setFocusToBackButton() {
      this.$el.querySelector('.group-back').focus();
    },
  },
  mounted() {
    this.setFocusToBackButton();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.user-settings-content {
  @include breakpoint-lg-down() {
    overflow-x: auto;
    width: 100vw;
    height: 100vh;
    position: absolute;
    padding-bottom: 90px;
    top: 0px;
    left: 0px;
    z-index: 1055;
    .first-item {
      margin-top: 65px;
    }
  }
  .header-dropdown-title {
    width: 85%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include breakpoint-lg-down() {
      display: block;
      text-align: center;
      height: 65px;
      width: 100%;
      &.fixed {
        position: fixed;
        font-size: 18px;
        top: 0px;
        left: 0px;
        z-index: 1040;
      }
      &.sub {
        background-color: $color-primary-darker-guardian;
        padding: 12px 15px !important;
        text-align: left;
        height: unset;
        width: 100%;
      }
    }

    .header-dropdown-close {
      @include breakpoint-lg-down() {
        display: none;
      }
    }
    .group-back {
      position: absolute;
      top: 20px;
      left: 20px;
      display: block;
      @include breakpoint-lg() {
        display: none;
      }
    }
  }
  .header-dropdown-subtitle {
    display: block;
    background-color: $color-primary-darker-guardian;
    color: $color-white;
    padding: 15px;
    @include breakpoint-lg() {
      display: none;
    }
  }
  .header-dropdown-item {
    @include breakpoint-lg-down() {
      padding-left: 30px;
      .forward-icon {
        display: none;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .profile-icon {
    display: none;
    @include breakpoint-lg-down() {
      display: block;
      background-color: $color-grey-base;
      text-align: center;
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      border-bottom: 1px solid $color-grey-dark;
      .item {
        position: relative;
        text-align: center;
        .user {
          width: 80px;
          margin: 0 auto;
          /deep/ .user-icon {
            background-color: $color-white;
            width: 80px;
            height: 80px;
            .user-img {
              max-width: 80px;
              max-height: 80px;
            }
            .shortname {
              font-size: 1.8em;
              .theme-admin & {
                color: $color-primary-darker-admin;
              }
              .theme-guardian & {
                color: $color-primary-darker-guardian;
              }
              .theme-employee & {
                color: $color-primary-darker-employee;
              }
              .theme-child & {
                color: $color-primary-darker-child;
              }
            }
          }
        }
        .item-title {
          display: block;
          width: 100%;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          margin-top: 10px;
          text-transform: uppercase;
        }
      }
    }
  }
}

#profile-modal {
  text-align: left;
}

.header-dropdown-title {
  display: none;
  width: 50%;
  @include breakpoint-lg() {
    display: block;
    padding-top: 0;
    line-height: 50px;
  }
}

//Theme styling
.theme-admin {
  .user-settings-content {
    @include breakpoint-lg-down() {
      background-color: $color-grey-light;
      .fixed {
        background-color: $color-primary-base-admin;
      }
      .sub {
        background-color: $color-primary-dark-admin !important;
      }
      .header-dropdown-subtitle {
        background-color: $color-primary-dark-admin;
      }
      .profile-icon {
        color: $color-primary-darker-admin;
      }
    }
  }
}

.theme-guardian {
  .user-settings-content {
    @include breakpoint-lg-down() {
      background-color: $color-grey-light;
      .fixed {
        background-color: $color-primary-base-guardian;
      }
      .sub {
        background-color: $color-primary-dark-guardian !important;
      }
      .header-dropdown-subtitle {
        background-color: $color-primary-dark-guardian;
      }
      .profile-icon {
        color: $color-primary-darker-guardian;
      }
    }
  }
}

.theme-employee {
  .user-settings-content {
    @include breakpoint-lg-down() {
      background-color: $color-grey-light;
      .fixed {
        background-color: $color-primary-base-employee;
      }
      .sub {
        background-color: $color-primary-dark-employee !important;
      }
      .header-dropdown-subtitle {
        background-color: $color-primary-dark-employee;
      }
      .profile-icon {
        color: $color-primary-darker-employee;
      }
    }
  }
}

.theme-child {
  .user-settings-content {
    @include breakpoint-lg-down() {
      background-color: $color-grey-light;
      .fixed {
        background-color: $color-primary-base-child;
      }
      .sub {
        background-color: $color-primary-dark-child !important;
      }
      .header-dropdown-subtitle {
        background-color: $color-primary-dark-child;
      }
      .profile-icon {
        color: $color-primary-darker-child;
      }
    }
  }
}
</style>
