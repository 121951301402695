import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { Validator } from './validator';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { NewMessage } from '../newMessage';
import { AttachmentModel } from '../../../shared/models/attachment.model';
import { MessageRecipient } from '../messageRecipient';
import Vue from 'vue';

export class NewMessageValidator extends Validator {
  public static readonly MAX_MESSAGE_WITHOUT_HTML = 6600;
  public readonly _message: NewMessage;

  constructor(message: NewMessage) {
    super();
    this._message = message;
  }

  private _validMessageTextLength: boolean = true;
  private _validMessageText: boolean = true;
  private _validMessageRecipientLimitWithPermission: boolean = true;
  private _validMessageRecipientLimit: boolean = true;
  private _validMessageRecipientLimitForMultiple: boolean = true;
  private _validMessageRecipientsNotEmpty: boolean = true;
  private _showMessageRecipientsLimitWarning: boolean = false;
  private _validSecureFileRecipients: boolean = true;
  private _validMessageSubject: boolean = true;
  private _validMessageOtpSender: boolean = true;
  private _validBCCMessageRecipientLimitForMultiple: boolean = true;

  private _showMessageRecipientsLimitWarningForEmployee: boolean = false;
  private _showMessageRecipientsLimitWarningForNonEmployee: boolean = false;

  public get validMessageTextLength(): boolean {
    return this._validMessageTextLength;
  }

  public get validMessageText(): boolean {
    return this._validMessageText;
  }

  public get validMessageRecipientLimit(): boolean {
    return this._validMessageRecipientLimit;
  }

  public get validMessageRecipientLimitForMultiple(): boolean {
    return this._validMessageRecipientLimitForMultiple;
  }

  public get validBCCMessageRecipientLimitForMultiple(): boolean {
    return this._validBCCMessageRecipientLimitForMultiple;
  }

  public get showMessageRecipientsLimitWarning(): boolean {
    return this._showMessageRecipientsLimitWarning;
  }

  public set showMessageRecipientsLimitWarning(value: boolean) {
    this._showMessageRecipientsLimitWarning = value;
  }

  public get validSecureFileRecipients(): boolean {
    return this._validSecureFileRecipients;
  }

  public get validMessageSubject(): boolean {
    return this._validMessageSubject;
  }

  public get validMessageOtpSender(): boolean {
    return this._validMessageOtpSender;
  }

  public get validMessageRecipientsNotEmpty(): boolean {
    return this._validMessageRecipientsNotEmpty;
  }

  public get validMessageRecipientLimitWithPermission(): boolean {
    return this._validMessageRecipientLimitWithPermission;
  }

  public get showMessageRecipientsLimitWarningForEmployee(): boolean {
    return this._showMessageRecipientsLimitWarningForEmployee;
  }

  public get showMessageRecipientsLimitWarningForNonEmployee(): boolean {
    return this._showMessageRecipientsLimitWarningForNonEmployee;
  }
  /**
   * validates model for Message
   * @returns {boolean} true - if model is valid
   */
  public validate(): boolean {
    return this.validateRules([
      this.validateMessageText,
      this.validateMessageSubject,
      this.validateMessageOtpSender,
      this.validateMessageRecipientsLimits,
      this.validateSecureFileRecipients,
      this.validateBCCMessageRecipientLimitForMultiple,
    ]);
  }

  // todo: probably for Bcc as well
  public validateSecureFileRecipients = (): boolean => {
    this._validSecureFileRecipients = this.isAllRecipientsAllowedToReceiveSecureFile(
      this._message.attachments,
      this._message.messageRecipients
    );
    return this._validSecureFileRecipients;
  };

  public validateMessageText = (): boolean => {
    const textWithoutHtml = Vue.filter('textWithoutHtml')(this._message.messageText);
    this._validMessageTextLength = textWithoutHtml.length <= NewMessageValidator.MAX_MESSAGE_WITHOUT_HTML;
    this._validMessageText = textWithoutHtml.length > 0;

    return this._validMessageTextLength && this._validMessageText;
  };

  public validateMessageSubject = (): boolean => {
    this._validMessageSubject = !Validator.isEmpty(this._message.messageSubject);
    return this._validMessageSubject;
  };

  public getIsEmptyMessage = (): boolean =>
    this._message.messageSubject === '' && !this._message.anyRecipients && this._message.messageText === '';

  private validateMessageRecipientLimitForMultiple = () => {
    const hasDistributionListPermission = Validator.hasAnyPermission([permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS]);
    if (hasDistributionListPermission) {
      return true;
    }
    return this._message.isMessageRecipientsNumberAboveLimit === false;
  };

  private validateBCCMessageRecipientLimitForMultiple = () => !this._message.isExceedingBccRecipientLimit;

  public validateMessageRecipientsLimits = (): boolean => {
    this._validMessageRecipientLimitForMultiple = true;
    this._validMessageRecipientLimit = true;
    this._validMessageRecipientLimitWithPermission = true;
    this._showMessageRecipientsLimitWarning = false;

    this._validMessageRecipientsNotEmpty =
      !Validator.isEmpty(this._message.messageRecipients) || !Validator.isEmpty(this._message.messageBccRecipients);
    if (!this._validMessageRecipientsNotEmpty) {
      return false;
    }

    const hasDistributionListPermission = Validator.hasAnyPermission([permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS]);

    this._validMessageRecipientLimitForMultiple = this.validateMessageRecipientLimitForMultiple();

    this._validBCCMessageRecipientLimitForMultiple = this.validateBCCMessageRecipientLimitForMultiple();

    this._validMessageRecipientLimitWithPermission = !(
      this._message.isMessageRecipientsNumberAboveLimit &&
      !this._message.hasTooManyRecipientsWarningAccepted &&
      hasDistributionListPermission
    );

    this._validMessageRecipientLimit = !(
      this._message.isMessageRecipientsNumberAboveLimit &&
      !this._message.hasTooManyRecipientsWarningAccepted &&
      !hasDistributionListPermission
    );

    this._showMessageRecipientsLimitWarning =
      !this._validMessageRecipientLimitWithPermission || !this._validMessageRecipientLimit;

    return (
      this._validMessageRecipientLimitForMultiple &&
      this._validMessageRecipientLimit &&
      this._validMessageRecipientLimitWithPermission
    );
  };

  public validateMessageRecipientsLimitsWithProfile() {
    this._showMessageRecipientsLimitWarningForEmployee = false;
    this._showMessageRecipientsLimitWarningForNonEmployee = false;

    this._validMessageRecipientLimitForMultiple = this.validateMessageRecipientLimitForMultiple();
    if (!this._validMessageRecipientLimitForMultiple) {
      return false;
    }

    const hasDistributionListPermission = Validator.hasAnyPermission([permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS]);

    this._showMessageRecipientsLimitWarningForEmployee =
      this._message.isMessageRecipientsNumberAboveLimit &&
      !hasDistributionListPermission &&
      this._message.profileRole === portalRoles.EMPLOYEE;
    this._showMessageRecipientsLimitWarningForNonEmployee =
      this._message.isMessageRecipientsNumberAboveLimit &&
      !hasDistributionListPermission &&
      this._message.profileRole !== portalRoles.EMPLOYEE;
  }

  private validateMessageOtpSender = (): boolean => {
    this._validMessageOtpSender =
      this._message.profileRole !== portalRoles.OTP || this._message.selectedOtpInbox !== null;
    return this._validMessageOtpSender;
  };

  private isAllRecipientsAllowedToReceiveSecureFile = (
    attachments: AttachmentModel[],
    messageRecipients: MessageRecipient[]
  ): boolean => {
    if (NewMessageValidator.isEmpty(attachments) || NewMessageValidator.isEmpty(messageRecipients)) {
      return true;
    }

    const shareableRecipients: AttachmentModel[] = attachments.filter(
      item => item.file && item.file.shareableGuardianIds != null
    );

    if (Validator.isEmpty(shareableRecipients)) {
      return true;
    }

    const childRecipientIds = this.getRecipientIdsOfPortalRole(messageRecipients, portalRoles.CHILD);
    const otpRecipientIds = this.getRecipientIdsOfPortalRole(messageRecipients, portalRoles.OTP);
    const guardiansIds = this.getRecipientIdsOfPortalRole(messageRecipients, portalRoles.GUARDIAN);

    const shareableRecipientIds = shareableRecipients.map(item => item.file.shareableGuardianIds).flat(1);
    const shareableGuardiansIds = guardiansIds.filter(item => shareableRecipientIds.includes(item));

    return (
      Validator.arraysHaveEqualSizes(shareableGuardiansIds, guardiansIds) &&
      Validator.isEmpty(childRecipientIds) &&
      Validator.isEmpty(otpRecipientIds)
    );
  };

  private getRecipientIdsOfPortalRole = (recipients: MessageRecipient[], portalRole: portalRoles): number[] =>
    recipients.filter(recipient => recipient.portalRole === portalRole).map(recipient => recipient.id);
}
