<template>
  <div v-if="widgetData" class="aula-widget-placeholder page-layout-padding">
    <template v-if="widgetData.type === widgetTypesEnum.SSO">
      <widget-link
        placement="group"
        :supplier="widgetData.widgetSupplier"
        :icon="widgetData.icon"
        :s-s-oaction="widgetData.url"
        :widget-title="widgetData.name"
        widget-type="SSO"
        :widget-i-d="widgetData.widgetId"
        :widget-version="widgetData.widgetVersion"
      />
    </template>
    <template v-else>
      <widget-content
        placement="group"
        :supplier="widgetData.widgetSupplier"
        :restricted-groups="widgetData.restrictedGroups"
        :widget-type="widgetData.type"
        :widget-i-d="widgetData.widgetId"
        :widget-version="widgetData.widgetVersion"
        :widget-url="widgetData.url"
        :notifications="getWidgetNotifications(widgetData)"
      />
    </template>
  </div>
</template>

<script>
import WidgetContent from './WidgetContent';
import WidgetLink from './WidgetLink';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { widgetTypes } from '../../../shared/enums/widgetTypes';
import {widgetUtil} from "../../../shared/utils/widgetUtil";

export default {
  data: function () {
    return {
      widgetTypesEnum: widgetTypes,
    };
  },
  computed: {
    ...mapGetters({
      menuItems: types.GET_ACTIVE_GROUP,
      notifications: types.GET_NOTIFICATIONS,
    }),
    widgetData() {
      if (this.menuItems != null && this.menuItems.validGroupWidgets != null) {
        const widget = this.menuItems.validGroupWidgets.filter(
          w => 'W' + w.widget.widgetId + 'V' + w.widget.widgetVersion == this.$route.params.id
        );
        if (widget.length > 0) {
          return widget[0].widget;
        }
      }
      return {};
    },
  },
  methods: {
    getWidgetNotifications(widget) {
      return widgetUtil.getNotifications(this.notifications, {widgetId: widget.id});
    },
  },
  components: {
    WidgetContent,
    WidgetLink,
  },
};
</script>
