import { portal } from '../../assets/plugins/axios/axios.js';
import { AxiosError } from 'axios';
import { HttpError } from '../../../src/business/httpError';
import { GetMessagesForThreadRequest } from '../../models/requests/getMessagesForThread.request';
import { ThreadWithMessagesModel } from '../../models/threadWithMessages.model';

class MessagingService {
  public getMessagesForThread = async (payload: GetMessagesForThreadRequest): Promise<ThreadWithMessagesModel> => {
    try {
      const { data } = await portal.get('?method=messaging.getMessagesForThread', { params: payload });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const messagingService = new MessagingService();
